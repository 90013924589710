<template>
    <div>
        <div class="vx-row ml-1 mt-3">
            <div class="vx-row mb-6" style="width: 70%">
                <div class="vx-col sm:w-1/4 w-full flex items-center">
                    <span>Customer Number</span>
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <multiselect v-model="customers.selected" :options="customers.options" placeholder="Type to search"
                        track-by="text" label="text" :max-height="125" :internal-search="false"
                        @search-change="handleSearchChange" @clear="handleSearchClear">
                    </multiselect>
                </div>
            </div>
            <div class="vx-row mb-6" style="width: 70%">
                <div class="vx-col sm:w-1/4 w-full flex items-center"></div>
                <div class="vx-col sm:w-3/4 w-full">
                    <vs-button color="primary" @click="handleFilter()">Filter</vs-button>
                </div>
            </div>
        </div>
        <div class="vx-row mb-12">
            <div :class="[detail ? 'core vx-col w-full mb-base md:w-1/2' : '', 'core vx-col w-full mb-base md:w-1/1',]">
                <vs-table search stripe border description :sst="true" :data="table.data" :max-items="table.length"
                    :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">
                    <template slot="header">
                        <vs-dropdown vs-trigger-click class="cursor-pointer">
                            <div
                                class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                                <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of {{ this.table.total
                                    }}</span>
                                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                            </div>
                            <vs-dropdown-menu>
                                <vs-dropdown-item v-for="item in table.limits" :key="item"
                                    @click="handleChangelength(item)">
                                    <span>{{ item }}</span>
                                </vs-dropdown-item>
                            </vs-dropdown-menu>
                        </vs-dropdown>
                    </template>
                    <template slot="thead">
                        <vs-th sort-key="from_currency">Action</vs-th>
                        <vs-th sort-key="to_currency">Customer Data</vs-th>
                        <vs-th sort-key="exchange_rate">SO Data</vs-th>
                        <vs-th sort-key="date_echange_rate">DO Data</vs-th>
                        <vs-th sort-key="date_echange_rate">POD Data</vs-th>
                        <vs-th sort-key="date_echange_rate">POD Status</vs-th>
                        <vs-th sort-key="date_echange_rate">POD Reversal</vs-th>
                    </template>

                    <template slot-scope="{ data }">
                        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                            <vs-td class="whitespace-no-wrap">
                                <div class="flex flex-row space-x-1">
                                    <vx-tooltip text="Print Picking List">
                                        <vs-button type="line" icon-pack="feather"
                                            @click="handlePrintPicking(data[indextr].DeliveryOrder.id)"
                                            icon="icon-printer" />
                                    </vx-tooltip>
                                    <vx-tooltip text="Detail">
                                        <vs-button type="line" icon-pack="feather"
                                            @click="handleDetail(data[indextr].DeliveryOrder.id)" icon="icon-eye" />
                                    </vx-tooltip>
                                    <vx-tooltip text="Confirm to Complete">
                                        <vs-button type="line" icon-pack="feather"
                                            @click="handleConfirm(data[indextr].DeliveryOrder.id)"
                                            icon="icon-shopping-cart" />
                                    </vx-tooltip>
                                    <vx-tooltip text="Cancel PI">
                                        <vs-button type="line" @click="handleCancelPI(data[indextr].DeliveryOrder.id)"
                                            icon-pack="feather" icon="icon-x" />
                                    </vx-tooltip>
                                </div>
                            </vs-td>

                            <vs-td :data="data[indextr].customer_data">{{ data[indextr].CustomerCode }} / {{
                                data[indextr].CustomerName }}</vs-td>
                            <vs-td :data="data[indextr].so_data">{{ data[indextr].Code }}</vs-td>
                            <vs-td :data="data[indextr].do_data">{{ data[indextr].DeliveryOrder.Code }}</vs-td>
                            <vs-td :data="data[indextr].do_data">
                                Code : <b>{{ data[indextr].DeliveryOrder.ProofOfDelivery.Code }}</b>
                                <br />
                                GI : <b>{{ data[indextr].DeliveryOrder.ProofOfDelivery.GiNumber }}</b>
                            </vs-td>
                            <vs-td :data="data[indextr].do_data">{{
                                formatStatusDelivery(data[indextr].DeliveryOrder.ProofOfDelivery.StatusDelivery)
                                }}</vs-td>
                            <!-- <vs-td :data="data[indextr].do_data">{{ data[indextr].DeliveryOrder.ProofOfDelivery.Status == 4 ? "YES" : "NO" }}</vs-td> -->
                            <vs-td :data="data[indextr].do_data">{{
                                data[indextr].DeliveryOrder.ProofOfDelivery.DateReversal ?
                                    formatDate(data[indextr].DeliveryOrder.ProofOfDelivery.DateReversal) : "NO" }}</vs-td>
                            <!-- <vs-td v-if="['D000', 'COD'].includes(data[indextr].PaymentTermName)" :data="data[indextr].do_data">{{ data[indextr].DeliveryOrder.ProofOfDelivery.Status == 4 ? data[indextr].DeliveryOrder.InvoiceLine.Invoice.Code : "" }}</vs-td>

                            <vs-td v-else :data="data[indextr].do_data">{{ data[indextr].DeliveryOrder.ProofOfDelivery.Status == 4 ? data[indextr].DeliveryOrder.InvoiceProforma.Code : "" }}</vs-td> -->
                        </vs-tr>
                    </template>
                </vs-table>
                <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />
            </div>

            <transition name="detail-fade">
                <div class="vertical-divider core vx-col w-full mb-base" v-if="detailS"
                    :class="[detail ? 'md:w-1/2' : 'close md:w-1/1']">
                    <vs-col style="padding-bottom: 8px" vs-offset="8" vs-type="flex" vs-justify="right" vs-align="right"
                        vs-w="4">
                        <vs-button class="ml-auto min-h-0" size="small" v-on:click="handleClose" color="grey"
                            icon-pack="feather" icon="icon-x-square">Close</vs-button>
                    </vs-col>
                    <vs-col class="flex flex-row align-middle min-h-0">
                        <div>
                            <vs-button size="small" v-on:click="handleClose" color="grey" icon-pack="feather"
                                icon="icon-x-square"></vs-button>
                        </div>
                        <vs-table :data="detailDo">
                            <thead>
                                <tr>
                                    <th>SKU Name</th>
                                    <th>HU</th>
                                    <th>Qty</th>
                                    <th>Batch Number</th>
                                    <th>Expired Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr :key="index" v-for="(pl, index) in detailDo">
                                    <td>{{ pl.ItemName }}</td>
                                    <td>{{ pl.ItemUnit }}</td>
                                    <td>{{ pl.Amount }}</td>
                                    <td>{{ pl.Batch }}</td>
                                    <td>{{ pl.ExpiredDate }}</td>
                                </tr>
                            </tbody>
                        </vs-table>
                    </vs-col>
                </div>
                <div v-else>
                    <!-- ... -->
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import Swal from 'sweetalert2'

export default {
    data() {
        return {
            detail: false,
            detailS: false,
            detailDo: [],
            table: this.tableDefaultState(),
            customers: {
                selected: { id: 0, text: "All" },
                options: [{ id: 0, text: "All" }],
            },
            reasons: [],
        };
    },
    methods: {
        handleFilter() {
            this.getData();
        },
        handleSearchChange(searchTerm) {
            this.$http.get("/api/wms/v1/master/customers", {
                params: {
                    search: searchTerm,
                },
            })
                .then((resp) => {
                    if (resp.code == 200) {
                        this.customers.options = [
                            { id: 0, text: "All" },
                            ...resp.data.records.map((c) => ({
                                id: c.id,
                                text: `${c.code} ${c.name}`,
                            })),
                        ];
                        this.$vs.loading.close();
                    } else {
                        console.log("DELIVERY PLANS DATA NOT FOUND");
                    }
                })
                .catch(error => {
                    console.log(error)
                });
        },
        handleSearchClear() {
            this.customers.options = [];
        },
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            this.getData();
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        handleClose() {
            this.detailS = false;
            this.detail = false;
            this.getData();
        },
        handleDetail(do_id) {
            this.$emit("edit", { do_id });
        },
        handleConfirm(do_id) {
            var getData = this.getData;
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Pickup Confirm to Complete Confirmation`,
                text: "Please confirm to process this data",
                accept: function () {
                    this.$vs.loading();
                    this.$http.patch(`/api/wms/v1/simple-outbound-planner/complete`, {
                        delivery_order_id: do_id,
                    })
                        .then((resp) => {
                            this.$vs.loading.close();
                            getData();
                            if (resp.code == 200) {
                                this.$vs.notify({
                                    color: "success",
                                    title: "Success",
                                    text: "The data was successfully proceessed",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            } else {
                                this.$vs.dialog({
                                    color: "danger",
                                    title: "Something went wrong",
                                    text: resp.message,
                                });
                            }
                        })
                        .catch((error) => {
                            this.$vs.loading.close();
                            console.log(error);
                            this.$vs.dialog({
                                color: "danger",
                                title: "Something went wrong",
                                text: error,
                            });
                        });
                },
            });
        },
        async handleCancelPI(do_id) {
            try {
                this.$vs.loading();
                await this.getReason()

                const inputOptions = {}
                this.reasons.forEach(reason => {
                    inputOptions[reason.id] = `${reason.reason}`
                })
                const response = await this.$http.get(`/api/wms/v1/simple-outbound-planner/do/${do_id}`)

                if (response.code != 200) {
                    throw new Error(response.message);
                }
                this.$vs.loading.close();

                const { data } = response
                const soCode = data.SalesOrder.Code
                const doCode = data.Code
                const podCode = data.ProofOfDelivery.Code
                const customerCode = data.SalesOrder.CustomerCode

                // const { isConfirmed, value: reasonID } = await Swal.fire({
                //     title: 'Cancel PI',
                //     text: `SO Code: ${soCode}, DO Code: ${doCode}, POD Code: ${podCode}, Customer Code: ${customerCode}`,
                //     input: "select",
                //     inputLabel: "Reason",
                //     inputOptions: inputOptions,
                //     showCancelButton: true,
                //     confirmButtonText: 'Confirm',
                //     inputValidator: (value) => {
                //         if (!value) {
                //             return "Please input reason!";
                //         }
                //     }
                // })
                const { isConfirmed, value: reason } = await Swal.fire({
                    title: 'Cancel PI',
                    html: `
                        <div style="text-align: left">
                          <ul>
                            <li><b>SO Code: </b> ${soCode}</li>
                            <li><b>DO Code: </b>${doCode}</li>
                            <li><b>POD Code: </b>${podCode}</li>
                            <li><b>Customer Code: </b>${customerCode}</li>
                          </ul>
                        </div>
                    `,
                    input: "text",
                    inputLabel: "Reason",
                    showCancelButton: true,
                    confirmButtonText: 'Confirm',
                    inputValidator: (value) => {
                        if (!value) {
                            return "Please input reason!";
                        }
                    }
                })

                if (isConfirmed) {
                    const doIds = [
                        do_id
                    ]
                    this.confirmCancelSo(doIds, reason)
                }
            } catch (error) {
                this.$vs.dialog({
                    color: "danger",
                    title: "Something went wrong",
                    text: error,
                });
            } finally {
                this.$vs.loading.close();
            }

        },
        async confirmCancelSo(deliveryOrderIDs, reason) {
            try {
                const body = {
                    "delivery_order_id": deliveryOrderIDs,
                    "reason": reason,
                }
                this.$vs.loading();

                const response = await this.$http.post('/api/wms/v1/simple-outbound-processing/cancel-so-release', body)

                if (response.code != 200) {
                    throw new Error(response.message);
                }

              this.$vs.loading.close();

              this.$vs.dialog({
                    color: "success",
                    title: "Success",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                    acceptText: "Confirm",
                    text: response.message,
              });

              this.$vs.loading();
              this.getData();
              this.$vs.loading.close();

            } catch (error) {
                this.$vs.loading.close();
                this.$vs.dialog({
                    color: "danger",
                    title: "Something went wrong",
                    text: error,
                });
            }
        },
        handlePrint(do_id) {
            window.open(`/outbound/simple-outbound-processing/pickup/print/${do_id}`);
        },
        handlePrintProformaInvoice(doId) {
            window.doIds = [doId];
            window.is_pod = true;
            window.open(`/outbound/simple-outbound-planner/surat-jalan/print3`);
        },
        handlePrintInvoice(deliveryOrder) {
            window.delivery_order_code = deliveryOrder.Code;
            window.open(`/outbound/simple-outbound-planner/invoice/print`);
        },
        handlePrintPicking(doId) {
            window.doIds = [doId];
            window.open(`/outbound/simple-outbound-processing/pickup/print-pickup-picking-list2`);
        },
        handlePrintAll() {
            // ...
        },
        print() {
            // ...
        },
        async getReason() {
            try {
                const response = await this.$http
                    .get(`/api/wms/v1/master/reason`, {
                        params: { reason_group: "Cancel PI" },
                    })

                if (response.code != 200) {
                    throw new Error(response.message);

                }
                const data = response.data.records

                this.reasons = [...data]
            } catch (error) {
                this.$vs.dialog({
                    color: "danger",
                    title: "Something went wrong",
                    text: error,
                });
            }
        },
        getData() {
            this.$vs.loading();
            this.$http.get("/api/wms/v1/simple-outbound-planner/do", {
                params: {
                    length: this.table.length,
                    page: this.table.page,
                    search: this.table.search,
                    order: this.table.order,
                    sort: this.table.sort,
                    customer_id: this.customers.selected.id,
                    status_delivery: 0,
                    pickup: true,
                },
            })
                .then((resp) => {
                    if (resp.code == 200) {
                        this.table.total = resp.data.total_record;
                        this.table.totalPage = resp.data.total_page;
                        this.table.totalSearch = resp.data.total_record_search;
                        this.table.length = resp.data.total_record_per_page;
                        this.table.data = resp.data.records;
                        this.setStartEnd();
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                })
                .catch(error => {
                    console.log(error)
                });
        },
        setStartEnd() {
            let valStart = this.table.length * this.table.page - this.table.length + 1;

            if (valStart > this.table.total) {
                valStart = 1;
            }

            if (this.table.total == 0) {
                valStart = 0;
            }

            let valEnd = this.table.length * this.table.page;

            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (this.table.totalSearch < this.table.total && this.table.search != "") {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        handleProcess() {
            if (this.selected.length) {
                this.$vs.loading();
                this.$http.patch("/api/wms/v1/simple-outbound-planner/process", {
                    picking_ids: this.selected,
                })
                    .then((resp) => {
                        if (resp.code == 200) {
                            this.$vs.loading.close();
                            this.getData();
                        } else {
                            this.$vs.loading.close();
                            this.$vs.dialog({
                                color: "danger",
                                title: "Something went wrong",
                                text: resp.message,
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    });
            }
        },
        handleCancel() {
            if (this.selected.length) {
                this.$vs.loading();
                this.$http.patch("/api/wms/v1/simple-outbound-planner/cancel", {
                    picking_ids: this.selected,
                })
                    .then((resp) => {
                        if (resp.code == 200) {
                            this.$vs.loading.close();
                            this.getData();
                        } else {
                            this.$vs.loading.close();
                            this.$vs.dialog({
                                color: "danger",
                                title: "Something went wrong",
                                text: resp.message,
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    });
            }
        },
    },
    mounted() {
        this.table = this.tableDefaultState();
        this.getData();
    },
    watch: {},
    computed: {
        formatDate: () => {
            return (val) => (val ? moment.utc(val).format("YYYY-MM-DD") : "");
        },
        formatStatusDelivery: () => {
            return (statusDelivery) =>
                statusDelivery == 1
                    ? "Complete"
                    : statusDelivery == 2
                        ? "Partial Complete"
                        : statusDelivery == 4
                            ? "Failed Cancel"
                            : "Open";
        },
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
    },
};
</script>

<style scoped>
.core {
    -webkit-transition: all 1s ease-out;
    -moz-transition: all 1s ease-out;
    -o-transition: all 1s ease-out;
    transition: all 1s ease-out;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
    font-size: 12px;
}

.vertical-divider {
    border-left: 1px solid #7367f0;
    min-height: 100px;
    padding: 5px;
}
</style>
