<template>
    <div>
        <vs-table search stripe border description :sst="true" :data="table.data" :max-items="table.length"
            :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div
                        class="flex items-center justify-between p-3 font-medium border border-solid rounded-full cursor-pointer d-theme-border-grey-light d-theme-dark-bg">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of
                            {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </template>
            <template slot="thead">
                <vs-th width="5%">Action </vs-th>
                <vs-th width="20%">File Info</vs-th>
                <vs-th width="10%">Download</vs-th>
                <vs-th width="20%">Import Process</vs-th>
                <vs-th width="5%">Status</vs-th>
                <vs-th>Remark</vs-th>
                <vs-th>External Codes</vs-th>
                <vs-th>Created Document</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="i" v-for="(tr, i) in data">
                    <vs-td>
                        <div class="flex vx-input-group">
                            <vs-button v-if="this.$store.getters['user/hasPermissions']('view')" size="small" color="grey" icon-pack="feather" icon="icon-eye" title="Detail"
                                style="margin-right: 5px" @click="handleDetail(tr.id)" />
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div>File Name : {{ tr.FileName }}</div>
                            <div>File Type : {{ tr.FileType }}</div>
                            <div>PO Types : {{ joinAgg(tr.POTypes) }}</div>
                            <div>Suppliers : {{ joinAgg(tr.SupplierCodes) }}</div>
                            <div>Warehouses : {{ joinAgg(tr.WarehouseCodes) }}</div>
                        </div>
                    </vs-td>                    
                    <vs-td><a v-if="tr.FileUrl" @click.stop="downloadFileAwsS3(tr.FilePath)">Download</a> <span v-else>None</span></vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div>Started : {{ formatDate(tr.StartProcessAt) }}</div>
                            <div>Ended : {{ formatDate(tr.EndProcessAt) }}</div>
                        </div>
                    </vs-td>
                    <vs-td>{{ tr.Status }}</vs-td>
                    <vs-td>{{ tr.Remark }}</vs-td>
                    <vs-td>
                        <div v-if="tr.ExternalCodes">
                            <div :key="i" v-for="(ec, i) in tr.ExternalCodes">
                                <p>{{ ec }}</p>
                            </div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div v-if="tr.Data">
                            <div :key="i" v-for="(cd, i) in tr.Data.created_docs">
                                <p>{{ cd }}</p>
                            </div>
                        </div>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />
    </div>
</template>
<script>
import moment from "moment";
export default {
    components: {},
    props: {
        draw: {
            type: Number,
        },
        date : {
            type: Date,
        },
        sourceWarehouseId: {
            type: String,
        },
        poTypeId: {
            type: String,
        },
        supplierId: {
            type: String,
        },
        externalCode: {
            type: String,
        },
        status: {
            type: String,
            default: "Pending",
        },
    },
    data() {
        return {
            deleteId: null,
            table: this.tableDefaultState(),
            checkedAll: false,
            checked: [],
            selectedRouteAssignmentToAssign: [],
            timeOuts: null,
        };
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            if (this.timeOuts) { clearTimeout(this.timeOuts) }
            this.timeOuts = setTimeout(this.getData, 900);
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        getData() {
            this.$vs.loading();
            this.$http
                .get(`/api/wms/v1/log/import`, {
                    params: {
                        limit: this.table.length,
                        page: this.table.page,
                        query: this.table.search,
                        order: this.table.order,
                        sort: this.table.sort,
                        warehouse_ids: this.sourceWarehouseId,
                        supplier_ids: this.supplierId,
                        external_code: this.externalCode,
                        po_type_ids: this.poTypeId,
                        status: this.status,
                        type: "import-po",
                        date: this.date,
                    },
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        this.checkedAll = false;
                        this.checked = [];
                        this.selectedRouteAssignmentToAssign = [];

                        this.table.total = resp.data.total_rows;
                        this.table.totalPage = resp.data.total_pages;
                        this.table.totalSearch = resp.data.total_record_search;
                        this.table.length = resp.data.limit;
                        this.table.data = resp.data.rows?resp.data.rows:[];
                        this.setStartEnd();
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        setStartEnd() {
            let valStart = this.table.length * this.table.page - this.table.length + 1;

            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }
            let valEnd = this.table.length * this.table.page;

            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (
                this.table.totalSearch < this.table.total &&
                this.table.search != ""
            ) {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        handleDetail(id) {
            this.$vs.loading();
            this.$router.push({
                name: this.$store.state.purchaseOrder.baseRouterName + ".import-log-detail",
                params: { id: id },
            });
        },
        utcToLocal(val) {
            if (val) {
                return moment(val)
                    .add(-7, "h")
                    .utc()
                    .local()
                    .format("DD MMM YYYY HH:mm:ss");
            }
        },
        joinAgg(types) {
            return types.join(", ");
        },
    },
    mounted() { 
        this.$store.dispatch("user/getPermissions", "purchasing-po")
    },
    watch: {
        draw() {
            this.getData();
        },
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('YYYY-MM-DD HH:mm:ss');
            };
        },
        formatLocalNumber: () => {
            return (val) => {
                return Number(val).toLocaleString("id-ID");
            };
        },
    },
};
</script>
  