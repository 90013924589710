<template>
    <vx-card :title="`Purchase Receive Put Away `">
        <div class="mb-6 vx-row">
            <vs-button @click="handleBack()" class="mt-2 ml-4" color="danger" icon-pack="feather"
                icon="icon-arrow-left">Back</vs-button>
        </div>
        <div class="flex md:flex-row">
            <div class="w-full pr-6 md:basis-1/2">
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Warehouse Code</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <vs-input :value="this.form.warehouse.code + ' - ' + this.form.warehouse.name" disabled
                            :readonly="true" class="w-full bg-grey-light"></vs-input>
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Supplier</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <vs-input :value="this.form.supplier.code + ' - ' + this.form.supplier.name" disabled
                            :readonly="true" class="w-full bg-grey-light"></vs-input>
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Purchase Data</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <vs-input :value="this.form.po_code" disabled :readonly="true" class="w-full bg-grey-light"></vs-input>
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>GR Document Number</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <vs-input :value="this.form.code" disabled :readonly="true" class="w-full bg-grey-light"></vs-input>
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Delivery Note Number</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <vs-input disabled v-model="form.delivery_order_number" class="w-full bg-grey-light" />
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Posting Date</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <datepicker name="date" disabled :inline="false" v-model="form.date" class="bg-grey-light"
                            placeholder="Select Date" :cleared="() => {
                                this.form.date = null
                            }">
                        </datepicker>
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Note</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <vs-textarea v-model="form.notes" width="100%" />
                    </div>
                </div>
            </div>
            <div class="w-full md:basis-1/2">
                <!-- <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Shipment Number</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <vs-input disabled v-model="form.shipment_number" class="w-full bg-grey-light" />
                    </div>
                </div> -->
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Attachment</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <div class="w-full vx-col sm:w-5/6 upload">                            
                            <input type="file"  
                                ref="attachment" 
                                accept=".jpg,.jpeg,.png,.pdf"
                                @change="handleChangeAttachment"/>
                            <vs-button v-if="$store.getters['user/hasPermissions']('edit')" class="ml-5" color="primary" @click="handleUpload()" type="filled">Upload Attachment</vs-button>                       
                        </div>
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <vs-divider>
                        List Attachment
                    </vs-divider>
                    <vs-list>
                        <vs-list-item v-for="(item, index) in form.attachment" :key="index"
                            :title="item.name" class="flex">
                            <div class="flex">
                                <a @click.stop="downloadFileAwsS3(item.path)"><vs-button color="success" type="flat"
                                        icon="file_download"></vs-button></a>
                                <vs-button v-if="$store.getters['user/hasPermissions']('edit')" color="danger" @click="handleDeleteAttachment(index, item.id)"
                                    type="flat" icon="delete"></vs-button>
                            </div>
                        </vs-list-item>
                    </vs-list>
                </div>
            </div>
        </div>
        <div class="mb-6 vx-row">
            <div class="w-full">
                <table width="100%" v-if="rerendr" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th colspan="3"></th>
                            <th colspan="2">GR/Conversion</th>
                            <th colspan="4">Batch/ED Data</th>
                            <th colspan="2">Put Away</th>
                        </tr>
                        <tr>
                            <th>
                                <div>Supplier</div>
                                <div>SKU Code</div>
                            </th>
                            <th>
                                <div>Internal</div>
                                <div>SKU Code</div>
                            </th>
                            <th>
                                <div>Internal</div>
                                <div>SKU Description</div>
                            </th>
                            <th>
                                <div>HU</div>
                            </th>
                            <th>
                                <div>Qty</div>
                            </th>
                            <th>
                                <div>Qty</div>
                            </th>
                            <th>
                                <div>Batch #</div>
                            </th>
                            <th>
                                <div>Expired Date</div>
                            </th>
                            <th>
                                <div>Check Status</div>
                            </th>
                            <th>
                                <div>Qty</div>
                            </th>
                            <th>
                                <div>Suggested Warehouse</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(line, index) in form.putaway_dto" :key="index">
                            <td class="td vs-table--td" style="padding: 1px">
                                <vs-input :value="line.supplier_sku_code" :readonly="true" disabled
                                    v-if="index == 0 || (form.putaway_dto[index].purchase_order_line_id !== form.putaway_dto[index - 1].purchase_order_line_id)"
                                    class="w-full bg-grey-light"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 1px">
                                <vs-input :value="line.internal_sku_code" :readonly="true" disabled
                                    v-if="index == 0 || (form.putaway_dto[index].purchase_order_line_id !== form.putaway_dto[index - 1].purchase_order_line_id)"
                                    class="w-full bg-grey-light"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 1px">
                                <vs-input :value="line.item_name" :readonly="true" disabled
                                    v-if="index == 0 || (form.putaway_dto[index].purchase_order_line_id !== form.putaway_dto[index - 1].purchase_order_line_id)"
                                    class="w-full bg-grey-light"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 1px">
                                <vs-input :value="line.hu_name" :readonly="true" disabled
                                    v-if="index == 0 || (form.putaway_dto[index].conversion_key !== form.putaway_dto[index - 1].conversion_key)"
                                    class="w-full bg-grey-light"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 1px">
                                <vs-input :value="line.conversion_quantity" :readonly="true" disabled
                                    v-if="index == 0 || (form.putaway_dto[index].conversion_key !== form.putaway_dto[index - 1].conversion_key)"
                                    class="w-full bg-grey-light"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 1px">
                                <vs-input :value="line.check_quantity" :readonly="true" disabled
                                    v-if="index == 0 || (form.putaway_dto[index].purchase_receive_line_id !== form.putaway_dto[index - 1].purchase_receive_line_id)"
                                    class="w-full bg-grey-light"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 1px">
                                <vs-input :value="line.batch_external" :readonly="true" disabled
                                    v-if="index == 0 || (form.putaway_dto[index].purchase_receive_line_id !== form.putaway_dto[index - 1].purchase_receive_line_id)"
                                    class="w-full bg-grey-light"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 1px">
                                <datepicker class="bg-grey-light" name="date" :inline="false" v-model="line.expired_date"
                                    v-if="index == 0 || (form.putaway_dto[index].purchase_receive_line_id !== form.putaway_dto[index - 1].purchase_receive_line_id)"
                                    disabled placeholder="Select Date">
                                </datepicker>
                            </td>
                            <td class="td vs-table--td" style="padding: 1px">
                                <vs-input :value="checkOptions[line.is_damaged].name" :readonly="true" disabled
                                    v-if="index == 0 || (form.putaway_dto[index].purchase_receive_line_id !== form.putaway_dto[index - 1].purchase_receive_line_id)"
                                    class="w-full bg-grey-light"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 1px">
                                <vs-input type="number" disabled @keypress="isNumber($event)" min="0" v-model="line.quantity" class="w-full bg-grey-light" />
                            </td>
                            <td class="td vs-table--td" style="padding: 1px">
                                <WarehouseArea :value="line.warehouse_area" :warehouse-id="form.warehouse.id"
                                    :damaged="line.is_damaged"
                                    :base-url="`/api/wms/v1/master/warehouse-areas/status`" label="name"
                                    track-by="name" @selected="(v) => { line.warehouse_area = v }">
                                </WarehouseArea>
                            </td>
                            <!-- <td class="td vs-table--td" style="padding: 1px">
                                <div class="flex vx-input-group">
                                    <vs-button @click.stop="removeItemsRow(index)"
                                        v-if="index > 0 && form.putaway_dto[index].purchase_receive_line_id == form.putaway_dto[index - 1].purchase_receive_line_id"
                                        size="small" color="danger" icon-pack="feather" icon="icon-trash"
                                        style="margin-right: 1px" title="Remove Row" />
                                    <vs-button @click.stop="addItemsRow(index)"
                                        v-if="index == 0 || form.putaway_dto[index].purchase_receive_line_id != form.putaway_dto[index - 1].purchase_receive_line_id"
                                        size="small" color="success" icon-pack="feather" icon="icon-plus" title="Add Row" />
                                </div>
                            </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="pt-6 mb-6 vx-row">
            <div class="w-full vx-col sm:w-1/1">
                <div
                    style=" position: absolute; left: 50%; -ms-transform: translate(-50%, -50%); transform: translate(-50%, -50%); ">
                    <vs-button v-if="$store.getters['user/hasPermissions']('edit')" class="mb-2 mr-3" @click="handleSubmit(false)">Save</vs-button>
                    <vs-button v-if="$store.getters['user/hasPermissions']('edit')" class="mb-2 mr-3" @click="handleSubmit(true)">Save and Confirm</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import Date from "@/components/Date.vue";
import WarehouseArea from "./component/WarehouseArea.vue";
import vSelect from "vue-select";
import moment from "moment";
import Datepicker from "vuejs-datepicker";

export default {
    components: {
        Date,
        WarehouseArea,
        vSelect,
        Datepicker,
    },
    mounted() {
        this.id = this.$route.params.id;
        this.mode = this.$route.params.mode;
        if (this.id) {
            this.initData();
        } else {
            this.$vs.loading();
            setTimeout(this.$vs.loading.close, 3200);
        }
        this.$store.dispatch("user/getPermissions", "purchase-order");
    },
    data() {
        return {
            rerendr:true,
            popShow: false,
            warehouseAreaOptions: [],
            checkOptions: [{
                value: 0,
                name: "Good",
            },{
                value: 1,
                name: "Damaged",
            },{
                value: 2,
                name: "Expired",
            }],
            dialog: {
                save: false,
            },
            id: null,
            attachment: null,
            attachmentIDs: [],
            form: {
                id: null,
                purchase_order_id: null,
                warehouse: null,
                supplier: null,
                status: "0",
                dn_date: moment().toDate(),
                purchaseData: null,
                attachment: [],
                code: null,
                date: null,
                notes: null,
                delivery_order_number: null,
                purchase_date: null,
                shipment_number: null,
                putaway_dto: null,
            },
        }
    },
    methods: {
        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if ((charCode < 48 || charCode > 57)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        addItemsRow(index) {
            const newItem = Object.assign({}, this.form.putaway_dto[index])
            newItem.id = 0;
            newItem.warehouse_area = null;
            newItem.warehouse_area_id = null;
            this.form.putaway_dto.splice(index + 1, 0, newItem);
            this.rerendr = false
            this.$nextTick(() => this.rerendr = true)
        },
        removeItemsRow(index) {
            if (this.form.putaway_dto[index].purchase_receive_line_id == this.form.putaway_dto[index - 1].purchase_receive_line_id) {
                this.form.putaway_dto.splice(index, 1)
            }
        },
        handleSaveConfirm() {
            this.form.status = "6";
            this.handleSubmit();
        },
        validateQuatity(itemLine) {
            var err = null;
            var baseQty = Number(itemLine.quantity);
            var putAwayQty = 0;
            itemLine.put_aways.forEach((p) => {
                putAwayQty += Number(p.quantity);
            })
            if (baseQty !== putAwayQty) {
                err = `Putaway Quantity (${putAwayQty}) should equal to base quantity (${baseQty})`;
            }
            return err
        },
        handleUpload() {
            let doUpload = true
            if (this.form.attachment == null) {
                this.form.attachment = []
            }
            
            var formData = new FormData();
            if (this.attachment.length > 0) {
                Array.from(this.attachment).forEach(item => {
                    if (item.size <= 5000000){
                        formData.append("files", item);
                    } else {
                        this.$vs.dialog({
                            type: "confirm",
                            color: "danger",
                            title: `Error`,
                            text: 'Attachment max 5MB',
                        });
                        doUpload = false
                    }
                });
            } else {
                this.$vs.dialog({
                    type: "confirm",
                    color: "danger",
                    title: `Error`,
                    text: 'Empty file',
                });
                doUpload = false
            }
            if (doUpload) {
                this.$vs.loading();
                this.$http
                .post(`${this.$store.state.inbound.purchaseOrder.baseUrlPath}/receive/upload-file/${this.form.id}`, formData)
                .then(resp => {
                    if (resp.code == 200) {
                        this.$vs.loading.close();
                        if (this.form.id == 0) {
                            this.attachmentIDs.push(resp.data.id)
                        }
                        this.form.attachment.push(resp.data)
                    } else {
                        this.$vs.loading.close();
                        this.$vs.dialog({
                            type: "confirm",
                            color: "danger",
                            title: `Error`,
                            text: `${resp.code} : ${resp.message}`,
                        });
                    }
                });
            }
            this.attachment = []
            this.$refs.attachment.value = null;
        },
        handleSubmit(confirm = false) {
            const prLineArr = this.form.putaway_dto;
            try {
                const putAways = [];
                prLineArr.forEach((v) => {
                    if (!v.warehouse_area) {
                        throw new Error("Please select warehouse area")
                    }
                    putAways.push({
                        id : Number(v.id),
                        quantity : Number(v.quantity),
                        warehouse_area_id : Number(v.warehouse_area.id),
                        purchase_receive_line_id : Number(v.purchase_receive_line_id),
                    });
                })
                this.$vs.loading();
                this.$http
                    .post(`${this.$store.state.inbound.purchaseOrder.baseUrlPath}/put-away/${this.id}?confirm=${confirm}`, putAways)
                    .then(resp => {
                        if (resp.code == 200) {
                            this.$vs.loading.close();
                            this.$vs.dialog({
                                type: "confirm",
                                color: "success",
                                title: `Data Updated`,
                                text: "Updated successfully",
                                accept: this.handleBack,
                                acceptText: `OK`,
                            });
                        } else {
                            this.$vs.loading.close();
                            this.$vs.dialog({
                                type: "confirm",
                                color: "danger",
                                title: `Error`,
                                text: `${resp.code} : ${resp.message}`,
                                accept: this.handleBack,
                                acceptText: `OK`,
                            });
                        }
                    });
            } catch (e) {
                this.$vs.dialog({
                    type: "confirm",
                    color: "danger",
                    title: `Error`,
                    text: e,
                });
            }
        },
        handleBack() {
            this.$vs.loading();
            this.$router.push({
                name: this.$store.state.inbound.purchaseOrder.baseRouterName,
            });
            this.$vs.loading.close();
        },
        handleChangeAttachment(event) {
            this.attachment = event.target.files ? event.target.files : null;
        },
        initData() {
            this.$vs.loading();
            this.$http
                .get(`${this.$store.state.inbound.purchaseOrder.baseUrlPath}/put-away/${this.id}`)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.form = resp.data;
                        this.form.supplier = {
                            code: resp.data.supplier_code,
                            name: resp.data.supplier_name,
                        };

                        this.form.putaway_dto.forEach((item) => {
                            if (item.quantity == 0) {
                                item.quantity = item.check_quantity;
                            }
                        })
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
    },
    computed: {
    },
}
</script>

<style>
td.td.vs-table--td {
    vertical-align: text-top !important;
}

.vs-input--input:disabled,
input:disabled {
    pointer-events: auto !important;
}

.vs-dialog-danger .vs-dialog footer .vs-button-primary{
    display: none;
}

.vs-dialog-success .vs-dialog footer .vs-button-primary{
    display: none;
}
</style>