<template>
  <vx-card :title="title">
    <vs-button
      class="mt-2"
      color="primary"
      type="border"
      icon-pack="feather"
      icon="icon-plus"
      @click="handleCreate()"
      v-if="hasPermission('create')"
      >Create</vs-button
    >

    <div class="vx-col mb-6 w-1/2 mt-8">
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Warehouse</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <multiselect
            class="selectExample"
            v-model="selectedWarehouse"
            :options="optionWarehouse"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="100"
            :limit="3"
            placeholder="Type to search"
            :searchable="true"
            :custom-label="customLableWarehouse"
          />
        </div>
      </div>

      <div class="vx-row mb-6" v-if="selected.tab == 0">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Date</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <date-range-picker
            style="min-height: 40px"
            class="w-full"
            ref="picker"
            opens="center"
            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
            :singleDatePicker="false"
            :timePicker="false"
            :timePicker24Hour="false"
            :showWeekNumbers="false"
            :showDropdowns="false"
            :autoApply="true"
            v-model="filterDate"
            :linkedCalendars="true"
          >
            <template v-slot:input="picker" style="min-width: 350px">
              {{ datePickerDateFormat(picker.startDate) }} -
              {{ datePickerDateFormat(picker.endDate) }}
            </template>
          </date-range-picker>
        </div>
      </div>
      <div class="vx-row mb-6" v-if="selected.tab == 1">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Date</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input
            v-model="date"
            class="w-full"
            type="date"
            name="date"
            autocomplete="off"
          ></vs-input>
        </div>
      </div>
      <div class="vx-row mb-6" v-if="selected.tab == 2">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Date</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input
            v-model="date"
            class="w-full"
            type="date"
            name="date"
            :max="maxHistoryDay"
            autocomplete="off"
          ></vs-input>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span></span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <div class="vx-row ml-4">
            <vs-button
              class="m-2"
              color="primary"
              type="border"
              icon-pack="feather"
              icon="icon-search"
              @click="onClickBtnFilter"
            >
              Filter
            </vs-button>
            <vs-button
              class="m-2"
              color="primary"
              type="border"
              icon-pack="feather"
              icon="icon-trash-2"
              @click="onClickBtnClearFilter"
              >Clear</vs-button
            >
          </div>
        </div>
      </div>
    </div>
    <vs-tabs v-model="selected.tab">
      <vs-tab label="Active" @click="onclikNew">
        <div class="tab-text">
          <Active
            :getData="this.table"
            @handleSearch="handleSearch"
            @handleChangePage="handleChangePage"
            @handleChangelength="handleChangelength"
            @handleSort="handleSort"
            @handleEdit="handleEdit"
            :warehouseID="this.selectedWarehouse.id"
            :filterActive="this.filterActive"
            @changeTab="selectedTab"
            :selected="selected"
            :permission="this.permission"
          ></Active>
        </div>
      </vs-tab>
      <vs-tab label="Daily-Update" on-click="onclikDaily">
        <div class="tab-text">
          <Daily
            :filterActive="this.filterActive"
            @checkFilter="handleCheckFilter"
            @changeTab="selectedTab"
            :selected="selected"
            :data="this.table.datadetail"
            :optionalDrivers="this.optionalDriver"
            :warehouseID="this.selectedWarehouse.id"
            :date="this.selected.date"
            :permission="this.permission"
          ></Daily>
        </div>
      </vs-tab>
      <vs-tab label="History" @click="onclikHistory">
        <div class="tab-text">
          <History
            :filterActive="this.filterActive"
            @checkFilter="this.filterActive"
            @changeTab="selectedTab"
            :selected="selected"
            :data="this.table.datadetail"
            :optionalDrivers="this.optionalDriver"
            :permission="this.permission"
          ></History>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import Active from "./_tab/table-active.vue";
import Daily from "./_tab/table-daily.vue";
import History from "./_tab/table-history.vue";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  props: {
    active: {
      type: Number,
    },
  },
  components: {
    Active,
    History,
    Daily,
    DateRangePicker,
  },
  mounted() {
    this.getPermission();
    this.getWarehouse();
    this.getData();
    const today = new Date();
    this.filterDate.startDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      1
    );
    this.filterDate.endDate = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );
    this.onClickBtnFilter();
  },
  data() {
    return {
      title: "Vehicle Available",
      optionWarehouse: [],
      selectedWarehouse: {},
      optionalDriver: [],
      selectedDriver: {},
      filterActive: false,
      dateHistory: "",
      filterDate: {
        startDate: "",
        endDate: "",
      },
      warehouseID: this.$userLogin.warehouse_id,
      table: this.tableDefaultState(),
      date: "",
      maxHistoryDay: moment().add(-1, "days").format("YYYY-MM-DD"),
      selected: {
        tab: 0,
        warehouse_id: 0,
        date: "",
      },
      isHistory: false,
      permission: [],
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        datadetail: [],
        length: 10,
        page: 1,
        search: "",
        order: "",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleCheckFilter() {
      console.log(">>>>>ini check filter v2", this.filterActive);
      if (this.filterActive) {
        this.filterActive = false;
      }
    },
    handleCreate() {
      this.$vs.loading();
      this.$router.push({
        name: "vehicle-availability-create",
        query: { action: "Create" },
      });
      this.$vs.loading.close();
    },
    datePickerDateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("dddd, MMMM Do YYYY");
      }
      return a;
    },

    getWarehouse() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/master/warehouses", {
          params: {
            length: null,
            order: "name",
            sort: "asc",
            by_personal: 1,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionWarehouse = resp.data.records;
              if (this.optionWarehouse.length > 0) {
                this.selectedWarehouse = {
                  id: this.$userLogin.warehouse_id,
                  code: this.$userLogin.warehouse_code,
                  name: this.$userLogin.warehouse_name,
                };
              } else {
                this.optionWarehouse = [];
                this.selectedWarehouse = {};
              }
            } else {
              this.optionWarehouse = [];
              this.selectedWarehouse = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    customLableWarehouse({ code, name }) {
      return `${code} ${name}`;
    },
    onClickBtnFilter() {
      this.warehouseID = this.selectedWarehouse.id;
      this.selected.date = this.date;
      if (this.selected.tab == 0) {
        this.getData();
      } else if (this.selected.tab == 1) {
        this.getDriver();
      } else if (this.selected.tab == 2) {
        this.isHistory = true;
        this.getDriver();
      }
      this.filterActive = true;
    },
    onClickBtnClearFilter() {
      console.log("OOOOO", this.selected.tab);
      this.warehouseID = this.$userLogin.warehouse_id;
      this.selectedWarehouse = {
        id: this.$userLogin.warehouse_id,
        code: this.$userLogin.warehouse_code,
        name: this.$userLogin.warehouse_name,
      };

      if (this.selected.tab != 0) {
        this.selected = {
          date: "",
          warehouse_id: 0,
        };
      } else {
        this.filterDate.startDate = null;
        this.filterDate.endDate = null;
      }

      this.filterActive = false;

      // if (this.selected.tab == 0) {
      //   // this.getData();
      // } else if (this.selected.tab == 1) {
      //   this.onclikDaily();
      // } else if (this.selected.tab == 2) {
      //   this.isHistory = true;
      //   this.getDriver();
      // }

      // this.getData();
    },
    getData() {
      this.$vs.loading();
      if (
        this.filterDate.startDate == null &&
        this.filterDate.endDate == null
      ) {
        this.filterDate.startDate = "";
        this.filterDate.endDate = "";
      } else {
        this.filterDate.startDate = moment(this.filterDate.startDate).format(
          "YYYY-MM-DD"
        );
        this.filterDate.endDate = moment(this.filterDate.endDate).format(
          "YYYY-MM-DD"
        );
      }
      this.$http
        .get("/api/wms/v1/driver-availability/all", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            warehouse_id: this.warehouseID
              ? this.warehouseID
              : this.$userLogin.warehouse_id,
            date_from: this.filterDate.startDate,
            date_to: this.filterDate.endDate,
          },
        })
        .then((resp) => {
          console.log("rrrrrrr", resp);
          if (resp.code == 200) {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;

            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
          this.filterActive = false;
        });
    },
    onclikHistory() {
      this.isHistory = true;
      if (this.date == "") {
        this.date = moment().add(-1, "days").format("YYYY-MM-DD");
      }

      this.selected.date = this.date;
      this.selected.tab = 2;
    },

    onclikNew() {
      this.isHistory = false;
      this.getData();
    },
    onclikDaily() {
      this.isHistory = false;

      this.warehouseID = this.selectedWarehouse.id;
      this.selected.warehouse_id = this.warehouseID;

      if (this.date == "") {
        this.date = moment().format("YYYY-MM-DD");
      }

      this.selected.date = this.date;
      this.selected.tab = 1;
      this.getDriver();
    },

    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 0;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleEdit(data) {
      console.log("ininnnnnnnnn", data);

      this.selectedTab(1, data);
    },
    selectedTab(value, data) {
      this.selectedWarehouse = {
        id: data.warehouse_id,
        code: data.warehouse_code,
        name: data.warehouse_name,
      };
      this.warehouseID = this.selectedWarehouse.id;
      this.selected.warehouse_id = this.warehouseID;
      this.selected.date = moment(data.date).format("YYYY-MM-DD");
      this.selected.tab = value;
      this.getDriver();
    },

    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },

    getDriver() {
      this.$vs.loading();
      this.table.datadetail = [];
      this.optionalDriver = [];
      this.$http
        .get("/api/wms/v1/driver-availability/driver-by-warehouse", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            warehouse_id: this.warehouseID,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionalDriver = resp.data.records;
              console.log("uuuu", this.optionalDriver);
              if (this.optionalDriver.length > 0) {
                // this.selectedDriver = this.optionalDriver[0];
              } else {
                this.optionalDriver = [];
                this.selectedDriver = {};
              }
            } else {
              this.optionalDriver = [];
              this.selectedDriver = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
          if (this.selected.tab == 2) {
            this.getDataDaily(this.warehouseID);
          }
        });
    },

    getDataDaily(warehouseID) {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/driver-availability/edit", {
          params: {
            warehouse_id: warehouseID,
            date: this.selected.date,
            is_history: this.isHistory,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records != null) {
              for (let index = 0; index < resp.data.records.length; index++) {
                var tempData = {};
                tempData.availability =
                  resp.data.records[index].is_availibility;
                tempData.id = resp.data.records[index].vehicle_id;
                tempData.number = resp.data.records[index].vehicle_name;
                tempData.zone_name = resp.data.records[index].zone_name;
                tempData.driver_id = resp.data.records[index].driver_id;
                tempData.date_from = resp.data.records[index].date_from;
                tempData.date_to = resp.data.records[index].date_to;
                tempData.total_do = resp.data.records[index].total_do;
                tempData.driver_name = resp.data.records[index].personal_name;
                tempData.indextr = index;

                this.optionalDriver.forEach((element) => {
                  if (element.id == tempData.driver_id) {
                    tempData.selectedDriver = element;
                  }
                });

                this.table.datadetail.push(tempData);
              }

              this.date = resp.data.item.date;
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },

    customLabelDriver({ Personal }) {
      return `${Personal.name}`;
    },
    getPermission() {
      this.$vs.loading();
      this.$http
        .get("/api/account/v1/permission", {
          params: {
            module: "saf-execute",
            source: "WMS",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code === 200) {
            this.permission = resp.data;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        });
    },
  },
  watch: {},
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
    hasPermission() {
      return (guardName) => {
        return this.permission.some((perm) => perm.GuardName === guardName);
      };
    },
  },

  filters: {
    formatDate(val) {
      return moment(val).add(-7, "h").utc().local().format("DD MMM YYYY");
    },
  },
};
</script>
