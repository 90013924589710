<template>
    <div>
        <vs-table search stripe border description :sst="true" :data="table.data" :max-items="table.length"
            :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div
                        class="flex items-center justify-between p-3 font-medium border border-solid rounded-full cursor-pointer d-theme-border-grey-light d-theme-dark-bg">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of
                            {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </template>
            <template slot="thead">
                <vs-th>Action</vs-th>
                <vs-th sort-key="">Supplier Data</vs-th>
                <vs-th sort-key="">PO Data</vs-th>
                <vs-th sort-key="">PO Date</vs-th>
                <vs-th sort-key="">GR Data</vs-th>
                <vs-th sort-key=""># of SKU</vs-th>
                <vs-th sort-key="">Total Qty {HU/Qty}</vs-th>
                <vs-th sort-key="">Passed</vs-th>
                <vs-th sort-key="">Damaged</vs-th>
                <vs-th sort-key="">Expired</vs-th>
                <vs-th sort-key="">Status</vs-th>
                <vs-th sort-key="">Invoice Data</vs-th>
            </template>
            <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                        <div class="flex vx-input-group">
                            <vs-button size="small" color="warning" icon-pack="feather" icon="icon-eye" title="View"
                                style="margin-right: 5px" @click="handleViewCompleted(tr.id)" v-if="$store.getters['user/hasPermissions']('view')"/>
                            <vs-button size="small" color="grey" icon-pack="feather" icon="icon-printer" title="Print"
                                style="margin-right: 5px" @click="handlePrintPdf(tr.id)" />
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div>Code : {{ tr.supplier_code }}</div>
                            <div>Name : {{ tr.supplier_name }}</div>
                            <div>City : {{ tr.supplier_city }}</div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div>Warehouse : {{ tr.warehouse.name }}</div>
                            <div>Code : {{ tr.po_code }}</div>
                            <div>PayTerm : {{ tr.payment_term_name }}</div>
                            <div>PostDate : {{ formatDate(tr.purchase_posting_date) }}</div>
                            <div>Purchase Type : {{ tr.purchase_type_name }}</div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div>Date : {{ formatDate(tr.purchase_date) }}</div>
                            <div>ETA : {{ formatDate(tr.eta) }}</div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div class="flex flex-col content-center">
                                <span>Code : {{ tr.code }} </span>
                                <span>DON : {{ tr.delivery_order_number }} </span>
                                <span>SN : {{ tr.shipment_number }} </span>
                            </div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div v-if="tr.hu_count > 0">{{ tr.hu_count }}</div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div v-if="tr.hu_lines">
                            <div v-for="(qty, hu) in tr.hu_lines" :key="hu" class="flex flex-col content-center">
                                <span>{{ qty }} {{ hu }}</span>
                            </div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div v-if="tr.passed_line">
                                <div v-for="(qty, p) in tr.passed_line" :key="p" class="flex flex-col content-center">
                                    <span>{{ qty }} {{ p }}</span>
                                </div>
                            </div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div v-if="tr.damaged_line">
                                <div v-for="(qty, d) in tr.damaged_line" :key="d" class="flex flex-col content-center">
                                    <span>{{ qty }} {{ d }}</span>
                                </div>
                            </div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div v-if="tr.expired_line">
                                <div v-for="(qty, e) in tr.expired_line" :key="e" class="flex flex-col content-center">
                                    <span>{{ qty }} {{ e }}</span>
                                </div>
                            </div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div>[{{ tr.is_partial == "1" ? 'Part' : 'Main' }}] {{ tr.po_status }}</div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div v-if="tr.invoice_number_internal">
                                <p>Code : {{ tr.invoice_number }}</p>
                                <p>Internal Code : {{ tr.invoice_number_internal }}</p>
                            </div>
                            <div v-else>No Invoice Data</div>
                        </div>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />
    </div>
</template>
<script>
import moment from "moment";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
export default {
    components: {},
    props: {
        draw: {
            type: Number,
        },
        poTypeId: {
            type: Number,
        },
        sourceWarehouseIds: {
            type: String,
        },
        poIds: {
            type: String,
        },
        supplierIds: {
            type: String,
        },
        isGetData: {
            type: Boolean,
        },
        // rangeDate: {
        //     default: null,
        // },
        // transactionDate: {
        //     type: Date,
        //     default: null,
        // },
        status: {
            type: String,
        },
    },
    data() {
        return {
            deleteId: null,
            table: this.tableDefaultState(),
            checkedAll: false,
            checked: [],
            selectedRouteAssignmentToAssign: [],
            timeOuts: null,
            permission: [],
        };
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            if (this.timeOuts) { clearTimeout(this.timeOuts) }
            this.timeOuts = setTimeout(this.getData, 900);
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        getData() {
            var poCodeIds = []
            if (Array.isArray(this.poCodes)) {
                this.poCodes.forEach(e => { if (e.id) poCodeIds.push(e.id) });
            }

            var params = {
                limit: this.table.length,
                page: this.table.page,
                query: this.table.search,
                order: this.table.order,
                sort: this.table.sort,
                warehouse_ids: this.sourceWarehouseIds,
                supplier_ids: this.supplierIds,
                ids: this.poIds,
                po_type_id: this.poTypeId,
                status: this.status,
            }
            // if (this.rangeDate) {
            //     params.start_date = this.rangeDate.startDate
            //     params.end_date = this.rangeDate.endDate
            // }
            this.$vs.loading();
            this.$http
                .get(this.$store.state.inbound.purchaseOrder.baseUrlPath + "/receive", {
                    params: params,
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        this.checkedAll = false;
                        this.checked = [];
                        this.selectedRouteAssignmentToAssign = [];

                        this.table.total = resp.data.total_rows;
                        this.table.totalPage = resp.data.total_pages;
                        this.table.totalSearch = resp.data.total_record_search;
                        this.table.length = resp.data.limit;
                        var rows = resp.data.rows;
                        if (rows) {
                            rows.forEach((row, ir) => {
                                if (row.purchase_receive_lines) {
                                    const prline = new Map();
                                    const huline = new Map();
                                    const damaged_line = new Map();
                                    const passed_line = new Map();
                                    const expired_line = new Map();
                                    var prCount = 0;
                                    var huCount = 0;
                                    row.purchase_order_lines.forEach(item => {
                                        huCount++;
                                        if (huline.has(item.item_unit)) {
                                            huline.set(item.item_unit, Number(huline.get(item.item_unit)) + Number(item.quantity))
                                        } else {
                                            huline.set(item.item_unit, item.quantity);
                                        }
                                    });
                                    row.purchase_receive_lines.forEach(item => {
                                        var unit_name = item.item_unit.unit ? item.item_unit.unit.name : ""
                                        if (Number(item.quantity) != 0) {
                                            if (item.is_damaged == 5) {
                                                prCount++;
                                                if (prline.has(unit_name)) {
                                                    prline.set(unit_name, Number(prline.get(unit_name)) + Number(item.quantity))
                                                } else {
                                                    prline.set(unit_name, item.quantity);
                                                }
                                            } else if (item.is_damaged == 3) {
                                                // huCount++;
                                                // if (huline.has(unit_name)) {
                                                //     huline.set(unit_name, Number(huline.get(unit_name)) + Number(item.quantity))
                                                // } else {
                                                //     huline.set(unit_name, item.quantity);
                                                // }
                                            } else if (item.is_damaged == 0) {
                                                if (passed_line.has(unit_name)) {
                                                    passed_line.set(unit_name, Number(passed_line.get(unit_name)) + Number(item.quantity))
                                                } else {
                                                    passed_line.set(unit_name, item.quantity);
                                                }
                                            } else if (item.is_damaged == 1) {
                                                if (damaged_line.has(unit_name)) {
                                                    damaged_line.set(unit_name, Number(damaged_line.get(unit_name)) + Number(item.quantity))
                                                } else {
                                                    damaged_line.set(unit_name, item.quantity);
                                                }
                                            } else if (item.is_damaged == 2) {
                                                if (expired_line.has(unit_name)) {
                                                    expired_line.set(unit_name, Number(expired_line.get(unit_name)) + Number(item.quantity))
                                                } else {
                                                    expired_line.set(unit_name, item.quantity);
                                                }
                                            }
                                        }
                                    });
                                    rows[ir].pr_lines = Object.fromEntries(prline);
                                    rows[ir].hu_lines = Object.fromEntries(huline);
                                    rows[ir].passed_line = Object.fromEntries(passed_line);
                                    rows[ir].damaged_line = Object.fromEntries(damaged_line);
                                    rows[ir].expired_line = Object.fromEntries(expired_line);
                                    rows[ir].pr_count = prCount;
                                    rows[ir].hu_count = huCount;
                                }
                            });
                        }
                        this.table.data = rows;
                        this.setStartEnd();
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        setStartEnd() {
            let valStart =
                this.table.length * this.table.page - this.table.length + 1;

            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }
            let valEnd = this.table.length * this.table.page;

            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (
                this.table.totalSearch < this.table.total &&
                this.table.search != ""
            ) {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        handleViewCompleted(prID) {
            this.$router.push({
                name: this.$store.state.inbound.purchaseOrder.baseRouterName + ".create-complete",
                params: { id: prID },
            });
        },
        handlePrint(id) {
            this.$router.push({
                name: this.$store.state.inbound.purchaseOrder.baseRouterName + ".print-complete",
                params: { id: id },
            });
        },
        handlePrintPdf(id) {
            console.log("do print pdf");
            this.$vs.loading();
            this.$http
                .get(`${this.$store.state.inbound.purchaseOrder.baseUrlPath}/put-away/${id}`)
                .then((resp) => {
                    console.log(resp);
                    const doc = new jsPDF({
                        orientation: 'l',
                        unit: 'in',
                        format: [9.5, 11]
                    });

                    const lineHeight = 1.5;
                    let currentY = 1; // Starting Y coordinate

                    // Header information
                    const header = [
                        ["Nama Cabang : " + resp.data.warehouse.code + " " + resp.data.warehouse.name],
                        ["Pada Hari/Tanggal : " + moment(resp.data.date).format("YYYY-MM-DD")],
                        ["Telah dilakukan pemeriksaan terhadap kiriman dari : " + resp.data.supplier_code + " " + resp.data.supplier_name],
                        ["Berikut detail yang telah kami terima :"]
                    ];
                    autoTable(doc, {
                        startY: currentY,
                        body: header,
                        theme: 'plain',
                        margin: { right: 0.5, left: 0.5 },
                        styles: { lineHeight: lineHeight, cellPadding: 0 },
                        columnStyles: { 0: { fontSize: 9 } }
                    });
                    currentY = doc.lastAutoTable.finalY + 0.5;

                    // Header1 Information (Transport Details)
                    const header1 = [
                        ["Nama Transporter", ": ", "No. PO", ": " + resp.data.po_code],
                        ["Nama Pengemudi", ": ", "No. Surat Jalan", ": " + resp.data.delivery_order_number],
                        ["No. Polisi Kendaraan", ": ", "Tanggal Surat Jalan", ": " + this.formatDate(resp.data.dn_date)]
                    ];
                    autoTable(doc, {
                        startY: currentY,
                        body: header1,
                        theme: 'plain',
                        margin: { right: 0.5, left: 0.5 },
                        styles: { lineHeight: 0, cellPadding: 0 },
                        columnStyles: {
                            0: { fontSize: 9, cellWidth: 1.5 },
                            1: { fontSize: 9, cellWidth: 3 },
                            2: { fontSize: 9, cellWidth: 1.5 },
                            3: { fontSize: 9 }
                        }
                    });
                    currentY = doc.lastAutoTable.finalY + 0.2;

                    // Line Table (Details Table)
                    const line = [
                        [
                            { content: "No.", rowSpan: 2 },
                            { content: "Kode Item", rowSpan: 2 },
                            { content: "Nama Barang", rowSpan: 2, styles: { halign: 'center' } },
                            { content: "Jumlah", rowSpan: 2 },
                            { content: "Hasil Pemeriksaan Cabang", colSpan: 6 },
                            { content: "Keterangan", rowSpan: 2 }
                        ], [
                            { content: "Baik", fontSize: '9', styles: { halign: 'center' } },
                            { content: "Rusak", fontSize: '9', styles: { halign: 'center' } },
                            { content: "  +  ", fontSize: '9', styles: { halign: 'center' } },
                            { content: "  -  ", fontSize: '9', styles: { halign: 'center' } },
                            { content: "No. Batch", fontSize: '9', styles: { halign: 'center' } },
                            { content: "Expired", fontSize: '9', styles: { halign: 'center' } }
                        ]
                    ];

                    let skuUnique = {};
                    let key = [];
                    resp.data.putaway_dto.forEach((v, i) => {
                        const skuKey = v.internal_sku_code + v.hu_name + v.batch_external;
                        if (skuUnique[skuKey]) {
                            skuUnique[skuKey].qty += v.check_quantity;
                            v.is_damaged === 0
                                ? skuUnique[skuKey].qty_pass += v.check_quantity
                                : skuUnique[skuKey].qty_damage += v.check_quantity;
                        } else {
                            skuUnique[skuKey] = {
                                "sku_code": v.internal_sku_code,
                                "item_name": v.item_name,
                                "unit_name": v.hu_name,
                                "qty": v.check_quantity,
                                "qty_pass": v.is_damaged === 0 ? v.check_quantity : 0,
                                "qty_damage": v.is_damaged !== 0 ? v.check_quantity : 0,
                                "batch_external": v.batch_external,
                                "expired_date": v.expired_date,
                                "keterangan": ""
                            };
                            key.push(skuKey);
                        }
                    });

                    key.forEach((k, i) => {
                        line.push([
                            i + 1,
                            skuUnique[k].sku_code,
                            skuUnique[k].item_name,
                            `${skuUnique[k].qty} ${skuUnique[k].unit_name}`,
                            `${skuUnique[k].qty_pass} ${skuUnique[k].unit_name}`,
                            `${skuUnique[k].qty_damage} ${skuUnique[k].unit_name}`,
                            "", "",
                            skuUnique[k].batch_external,
                            skuUnique[k].expired_date,
                            ""
                        ]);
                    });

                    autoTable(doc, {
                        startY: currentY,
                        body: line,
                        theme: "grid",
                        margin: { right: 0.5, left: 0.5 },
                        styles: { halign: 'center', fontSize: 9, lineWidth: 0.01 },
                        columnStyles: {
                            0: { halign: 'center', fontSize: 9 },
                            1: { halign: 'center', fontSize: 9 },
                            2: { halign: 'left', fontSize: 9 },
                            3: { halign: 'center', fontSize: 9 }
                        },
                        willDrawPage: (data) => {
                            if (data.pageNumber > 1) {
                                data.cursor.y = 1
                            }
                        }
                    });
                    currentY = doc.lastAutoTable.finalY + 0.2;

                    // Footer
                    const footer = [
                        ["Demikian Berita Acara ini kami buat dengan sebenar-benarnya.", "", ""],
                        ["", "", ""],
                        ["", "", ""],
                        ["Dikirim Oleh,", "Diterima Oleh,", "Diketahui Oleh,"],
                        ["Transporter/Pengirim", "WHS SPV/PIC Logistik", "BLC/OS/APJ"],
                        ["", "", ""],
                        ["", "", ""],
                        ["(                                 )", "(                                 )", "(                                 )"],
                        ["Tanggal:                        ", "Tanggal:                        ", "Tanggal:                        "]
                    ];
                    autoTable(doc, {
                        startY: currentY,
                        body: footer,
                        theme: "plain",
                        margin: { right: 0.5, left: 0.5 },
                        styles: { lineHeight: lineHeight, cellPadding: 0 },
                        columnStyles: {
                            0: { halign: 'center', fontSize: 9, cellWidth: 3.5 },
                            1: { halign: 'center', fontSize: 9, cellWidth: 3.5 },
                            2: { halign: 'center', fontSize: 9, cellWidth: 3.5 }
                        },
                        willDrawPage: (data) => {
                            if (data.cursor.y <= 0.9) {
                                data.cursor.y = 1
                            }
                        },
                        pageBreak: "avoid"
                    });

                    // Add page numbers
                    const pageCount = doc.internal.getNumberOfPages();
                    for (let i = 1; i <= pageCount; i++) {
                        doc.setPage(i);
                        doc.setFontSize(15).text("BERITA ACARA PENERIMAAN BARANG ", 5.5, 0.5, { align: "center" });
                        doc.setFontSize(13).text(resp.data.code, 5.5, 0.7, { align: "center" });
                        doc.setFontSize(10).text(`Halaman ${i} dari ${pageCount}`, 10.1, 9.3, null, null, "right");                        
                    }

                    // Open PDF
                    const blob = doc.output('blob');
                    const url = URL.createObjectURL(blob);
                    window.open(url, '_blank');

                    // doc.save(
                    //     "Inbound-GR-" + resp.data.code + ".pdf"
                    // );      
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    console.log(e);
                    this.$vs.loading.close();
                });
        },
        utcToLocal(val) {
            if (val) {
                return moment(val)
                    .add(-7, "h")
                    .utc()
                    .local()
                    .format("DD MMM YYYY HH:mm:ss");
            }
        },
    },
    mounted() {
        this.$store.dispatch("user/getPermissions", "purchase-order");
    },
    watch: {
        draw() {
            this.getData();
        },
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                if (this.isGetData){
                    this.handleChangePage(val);
                }
            },
        },
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('YYYY-MM-DD');
            };
        },
        formatLocalNumber: () => {
            return (val) => {
                return Number(val).toLocaleString("id-ID");
            };
        },
    },
};
</script>
  