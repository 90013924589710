<template>
    <div>
        <vs-table search stripe border description :sst="true" :data="table.data" :max-items="table.length"
            :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div
                        class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of
                            {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </template>
            <template slot="thead">
                <vs-th>Action</vs-th>
                <vs-th sort-key="">Discount Code / Name</vs-th>
                <vs-th sort-key="">Supplier</vs-th>
                <vs-th sort-key="">Warehouse</vs-th>
                <vs-th sort-key="">Validity</vs-th>
                <template v-if="status === 2">
                    <vs-th sort-key="">Amended Data</vs-th>
                </template>
            </template>
                
            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                        <div class="vx-input-group flex">
                            <template v-if="status === 0">
                                <!-- Buttons for status 0 draft-->
                                <vs-button v-if="hasPermission('edit')" class="mr-1" size="small" color="warning" icon-pack="feather" icon="icon-edit" title="Edit" @click="handleEdit(tr.id)" />
                                <vs-button v-if="hasPermission('cancel')" class="mr-1" size="small" color="danger" icon-pack="feather" icon="icon-x" title="Cancel" @click="handleCancel(tr.id)" />
                                <vs-button v-if="hasPermission('edit')" class="mr-1" size="small" color="success" icon-pack="feather" icon="icon-check" title="Confirm" @click="handleConfirm(tr.id)" />
                            </template>
                            <template v-else-if="status === 1">
                                <!-- Buttons for status 3 release -->
                                <vs-button v-if="hasPermission('edit')" class="mr-1" size="small" color="warning" icon-pack="feather" icon="icon-edit" title="Amend" @click="handleAmend(tr.id)" />
                                <vs-button v-if="hasPermission('create')" class="mr-1" size="small" color="danger" icon-pack="feather" icon="icon-x" title="In-Active" @click="handleInactive(tr.id)" />
                                <vs-button v-if="hasPermission('view')" class="mr-1" size="small" color="success" icon-pack="feather" icon="icon-eye" title="View" @click="handleView(tr.id)" />
                            </template>
                            <template v-else>
                                <vs-button v-if="hasPermission('view')" class="mr-1" size="small" color="warning" icon-pack="feather" icon="icon-eye" title="View" @click="handleView(tr.id)" />
                            </template>
                        </div>
                    </vs-td>
                    <vs-td>{{ tr.code }} / {{ tr.name }}</vs-td>
                    <vs-td>{{ tr.supplier_code }} - {{ tr.supplier_name }}</vs-td>
                    <vs-td>{{ tr.wh_name }}</vs-td>
                    <vs-td>{{ formatDate(tr.valid_from) }} - {{ formatDate(tr.valid_to) }}</vs-td>
                    <template v-if="status === 2">
                        <vs-td>{{ utcToLocal(tr.date_amend) }} - {{ tr.user_name }}</vs-td>
                    </template>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />
    </div>
</template>
<script>
import moment from "moment";
export default {
    components: {},
    props: {
        draw: {
            type: Number,
        },
        warehouseId: {
            type: Number,
        },
        supplierId: {
            type: String,
        },
        skuId: {
            type: String,
        },
        status: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            confirmId: null,
            table: this.tableDefaultState(),
        };
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
                permission: [],
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            this.getData();
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        handleClose() {
            this.getData();
            this.$emit("close");
        },
        // handleCreate() {
        //     this.customerId = null;
        //     this.detail = true;
        // },
        getData() {
            this.$vs.loading();
            this.$http
            .get(this.$store.state.master.purchaseDiscount.baseUrlPath, {
                params: {
                    length: this.table.length,
                    page: this.table.page,
                    search: this.table.search,
                    order: this.table.order,
                    sort: this.table.sort,
                    status: this.status,
                    supplier_id: this.supplierId,
                    sku_id: this.skuId,
                },
            })
            .then((resp) => {
                console.log("RESP",resp)
                if (resp.code == 200) {
                    this.table.total = resp.data.total_record;
                    this.table.totalPage = resp.data.total_page;
                    this.table.totalSearch = resp.data.total_record_search;
                    this.table.length = resp.data.total_record_per_page;
                    this.table.data = resp.data.records;
                    this.setStartEnd();
                    this.$vs.loading.close();
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                }
            });
        },
        setStartEnd() {
            let valStart =
                this.table.length * this.table.page - this.table.length + 1;

            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }
            let valEnd = this.table.length * this.table.page;

            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (
                this.table.totalSearch < this.table.total &&
                this.table.search != ""
            ) {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        handleConfirm(id) {
            this.deleteId = id;
            this.$vs.dialog({
                type: "confirm",
                title: `Confirm`,
                text: "Are you sure to confirm this data?",
                accept: async () => {
                    this.$vs.loading();
                    const confirmStatus = 1;
                    this.$http.patch(`${this.$store.state.master.purchaseDiscount.baseUrlPath}/${id}/${confirmStatus}`)
                        .then((resp) => {
                            this.$vs.loading.close();
                            console.log("RESP",resp)
                            if (resp.code == 200) {
                                if (resp.data) {
                                    this.$vs.dialog({
                                        color: "danger",
                                        title: "Error",
                                        text: resp.data +'. Do you want to proceed with the release?',
                                        acceptText: "Yes",
                                        cancelText: "No",
                                        accept: () => {
                                            this.handleRelease(id);
                                        },
                                    });
                                    this.getData();
                                } else {
                                    this.$vs.notify({
                                        color: "success",
                                        title: "Success",
                                        text: "The data was successfully Released",
                                        position: "top-right",
                                        iconPack: "feather",
                                        icon: "icon-x-circle",
                                    });
                                    this.getData();
                                }
                            } else {
                                this.$vs.notify({
                                    color: "danger",
                                    title: "Error",
                                    text: resp.message,
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            }
                        });
                },
            });
        },
        handleRelease(id) {
            this.deleteId = id;
            const confirmStatus = 5;
            this.$http.patch(`${this.$store.state.master.purchaseDiscount.baseUrlPath}/${id}/${confirmStatus}`)
                .then((resp) => {
                    this.$vs.loading.close();
                    console.log("RESP",resp)
                    if (resp.code == 200) {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: "The data was successfully Released",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                        this.getData();
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    }
                });
        },
        handleCancel(id) {
            this.deleteId = id;
            this.$vs.dialog({
                type: "confirm",
                title: `Confirm`,
                text: "Are you sure to cancel this data?",
                accept: () => {
                    this.$vs.loading();
                    const cancelStatus = 4;
                    this.$http.patch(`${this.$store.state.master.purchaseDiscount.baseUrlPath}/${id}/${cancelStatus}`)
                        .then((resp) => {
                            this.$vs.loading.close();
                            if (resp.code == 200) {
                                this.$vs.notify({
                                    color: "success",
                                    title: "Success",
                                    text: "The data was successfully Cancel",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                                this.getData();
                            } else {
                                this.$vs.notify({
                                    color: "danger",
                                    title: "Error",
                                    text: resp.message,
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            }
                        });
                },
            });
        },
        handleInactive(id) {
            this.deleteId = id;
            this.$vs.dialog({
                type: "confirm",
                title: `Confirm`,
                text: "Are you sure to in-active this data?",
                accept: () => {
                    this.$vs.loading();
                    const Status = 3;
                    this.$http.patch(`${this.$store.state.master.purchaseDiscount.baseUrlPath}/${id}/${Status}`)
                        .then((resp) => {
                            this.$vs.loading.close();
                            if (resp.code == 200) {
                                this.$vs.notify({
                                    color: "success",
                                    title: "Success",
                                    text: "The data was successfully In-active",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                                this.getData();
                            } else {
                                this.$vs.notify({
                                    color: "danger",
                                    title: "Error",
                                    text: resp.message,
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            }
                        });
                },
            });
        },
        handleEdit(id) {
            console.log('tes',this.$store.state.master.purchaseDiscount.baseRouterName)
            this.$router.push({
                name: this.$store.state.master.purchaseDiscount.baseRouterName + "-edit",
                params: { id: id, tab: this.status },
            });
        },
        handleAmend(id) {
            console.log('tes',this.$store.state.master.purchaseDiscount.baseRouterName)
            this.$router.push({
                name: this.$store.state.master.purchaseDiscount.baseRouterName + "-amend",
                params: { id: id, tab: this.status },
            });
        },
        handleView(id) {
            console.log('tes',this.$store.state.master.purchaseDiscount.baseRouterName)
            this.$router.push({
                name: this.$store.state.master.purchaseDiscount.baseRouterName + "-view",
                params: { id: id, tab: this.status },
            });
        },
        utcToLocal(val) {
            if (val) {
                return moment(val)
                    .add(-7, "h")
                    .utc()
                    .local()
                    .format("DD MMM YYYY HH:mm:ss");
            }
        },
        DateFormat(dateTimeString) {
            const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
            const formattedDate = new Date(dateTimeString).toLocaleString(undefined, options);
            return formattedDate;
        },
        getPermission() {
            this.$vs.loading();
            this.$http
                .get("/api/account/v1/permission", {
                params: {
                    module: "purchase-discount",
                    source: "WMS"
                }
                })
                .then((resp) => {
                this.$vs.loading.close();
                if (resp.code === 200) {
                    this.table.permission = resp.data;
                } else {
                    this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: resp.message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                    });
                }
            });
        },
    },
    mounted() { 
        this.getPermission();
    },
    watch: {
        // sourceWarehouseId() {
        //     this.getData();
        // },
        // transactionDate() {
        //     this.getData();
        // },
        // status() {
        //     this.getData();
        // },
        draw() {
            this.getData();
        },
    },
    computed: {
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('DD MMM YYYY');
            };
        },
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
        hasPermission() {
            return (guardName) => {
                return this.table.permission.some((perm) => perm.GuardName === guardName);
            };
        },
    },
};
</script>
  