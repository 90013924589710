<style>
.bg-readonly input, .bg-readonly textarea {
    background-color: rgb(235, 235, 235);
}
.remove-button, .add-button {
  font-size: 1.5em;
  background-color: #ff5c5c; /* Red color for remove button */
  color: #fff; /* White text color for buttons */
  border: none;
  cursor: pointer;
  padding: 0.2em 0.5em;
  margin: 0.2em;
  border-radius: 5px;
}
.add-button {
  background-color: #5cb85c;
  margin-right: 10px;
}
.input-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
label {
  margin-right: 5px;
}
.set-readonly input, .set-readonly textarea{
  /* background-color: rgb(235, 235, 235); */
  pointer-events: none;
}
.set-readonly1{
  /* background-color: rgb(235, 235, 235); */
  pointer-events: none;
}
.customPromptWH .vs-dialog footer {
    display: none;
}
.button-container {
    display: flex;
    align-items: center;
}
.customeDate .vdp-datepicker__calendar {
    position: relative !important;
    top: 0px;
    left: 0px;
}
</style>
<template>
    <vx-card :title="(this.id ? 'Detail' : 'Create') + ' Purchase Discount - FORM'">
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Discount Code</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="data.discount_code" :readonly="true" :class="{ 'set-readonly': data.sd_id !== null, 'bg-readonly': data.sd_id === null  }" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Discount Name</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input type="text" v-model="data.discount_name" class="w-full" :class="{ 'set-readonly': data.sd_id !== null}" required></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Valid From</span>
            </div>
            <div class="customeDate vx-col sm:w-2/3 w-full" :class="{ 'set-readonly': data.sd_id !== null }">
                <datepicker name="date" :inline="false"
                    v-model="data.valid_from" placeholder="Select Date"
                    :cleared="() => {
                        data.valid_from = null
                    }" required>
                </datepicker>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Valid To</span>
            </div>
            <div class="customeDate vx-col sm:w-2/3 w-full" :class="{ 'set-readonly': data.sd_id !== null }">
                <datepicker name="date" :inline="false"
                    v-model="data.valid_to" placeholder="Select Date"
                    :cleared="() => {
                        data.valid_to = null
                    }" required>
                </datepicker>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%"> 
            <div class="vx-col sm:w-1/3 w-full flex items-center"> 
                <span>Created By</span> 
            </div> 
            <div class="vx-col sm:w-2/3 w-full"> 
                <div class="vx-row"> 
                    <div class="vx-col sm:w-5/12 w-full">                         
                        <vs-input :value="data.created_by" :readonly="true" :class="{ 'set-readonly': data.sd_id !== null, 'bg-readonly': data.sd_id === null  }" class="w-full"></vs-input> 
                    </div> 
                    <div class="vx-col sm:w-2/12 w-full flex items-center text-center"> 
                        <span>Created Date</span> 
                    </div> 
                    <div class="vx-col sm:w-5/12 w-full"> 
                        <vs-input :value="formatDate(this.data.created_date)" :readonly="true" :class="{ 'set-readonly': data.sd_id !== null, 'bg-readonly': data.sd_id === null  }" class="w-full"></vs-input> 
                    </div>                     
                </div> 
            </div>             
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Currency Code</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full" :class="{ 'set-readonly1': data.sd_id !== null }">
                <CustomMSelect :value="data.currency_code" base-url="/api/wms/v1/master/currencies" label="code"
                track-by="code" @selected="(v) => {
                    this.data.currency_code = v;
                }" required></CustomMSelect>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Notes</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full" :class="{ 'set-readonly': data.sd_id !== null }">
                <vs-textarea :readonly="statusReadonly" v-model="data.note" width="100%" :class="statusReadonly ? 'bg-readonly' : ''"/>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Attachment</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full" :class="{ 'set-readonly': data.sd_id !== null }">
                <input type="file" ref="attechment" accept=".jpg,.jpeg, .png" @change="handleChangeAttachment" />
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%" v-if="this.data.fileUrl != ''">
            <div class="vx-col sm:w-1/3 w-full flex items-center">

            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <div class="op-block mt-5">
                    <vs-list>
                        <vs-list-item title="File 1">
                            <div class="vx-input-group flex">
                                <vs-button size="small" color="success" icon-pack="feather" icon="icon-download"
                                    title="Download" class="mr-1" @click="openTab(fileUrl)" />
                                <!-- <vs-button size="small" color="danger" icon-pack="feather" icon="icon-trash"
                                    title="Delete" /> -->
                            </div>
                        </vs-list-item>
                    </vs-list>
                </div>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Applicable to:</span>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Supplier</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full" :class="{ 'set-readonly1': data.sd_id !== null }">
                <CustomMSelectSupplier :value="data.selectedSupplier" base-url="/api/wms/v1/master/suppliers" :custom-label="customSupplier"
                track-by="id" @input="handleSupplierChange" @selected="(v) => {
                    this.data.selectedSupplier = v;
                }" required></CustomMSelectSupplier>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Warehouse</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full" :class="{ 'set-readonly1': data.sd_id !== null }">
                <CustomMSelectWH :value="wh[0].selectedWarehouse" multiple base-url="/api/wms/v1/master/warehouses" label="name"
                track-by="id" @input="handleWarehouseChange" @selected="(v) => {
                    this.wh[0].selectedWarehouse = v;
                }" required></CustomMSelectWH>
                <vs-button v-if="data.sd_id === null" color="primary" type="line" title="Add Multi Warehouse" icon-pack="feather" icon="icon-arrow-right" @click="handlePrompt()" />
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full mb-6">
                <table width="100%" class="vs-table vs-table--tbody-table mb-6">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="5%">SOURCE</th>
                            <th width="5%"></th>
                        </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(source, index) in sources" :key="index" style="padding-bottom: 5px">
                        <td class="td vs-table--td" style="padding: 5px" :class="{ 'set-readonly1': data.sd_id !== null }">
                            <v-select
                                v-model="source.sourceModel"
                                :options="source.sourceOptions"
                                @input="updateSource(source, index)"
                                placeholder="All SKU, SKU, SKU Category" required
                            />
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full mb-6">
                <table width="100%" class="vs-table vs-table--tbody-table mb-6">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="5%">SKU/SKU Category</th>
                            <th width="5%"></th>
                        </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(sourceSku, index) in sourceSkus" :key="index" style="padding-bottom: 5px">
                        <td class="td vs-table--td" style="padding: 5px" :class="{ 'set-readonly1': data.sd_id !== null }">
                            <template v-if="sourceSku.selectedSourceModel === 'SKU'">
                                <CustomMSelectSKU :value="sourceSku.itemId"
                                :base-url="generateItemApiUrl()"
                                label="sku_code" track-by="id" 
                                @selected="(v) => {
                                    sourceSku.itemId = v;
                                    sourceSku.idItems = v.id;
                                    currentItemId.push(v.id);
                                    }"></CustomMSelectSKU>
                            </template>
                            
                            <template v-else-if="sourceSku.selectedSourceModel === 'SKU Category'">
                                <CustomMSelectCategory
                                :value="sourceSku.itemId"
                                :base-url="generateItemCategoryApiUrl()"
                                label="name" track-by="name"
                                @selected="(v) => {
                                    sourceSku.itemId = v;
                                    sourceSku.idItems = v.id;
                                    currentItemCategoryId.push(v.id);
                                    }"></CustomMSelectCategory>
                            </template>
                        </td>
                        <td v-if="sourceSku.selectedSourceModel === 'SKU' ||sourceSku.selectedSourceModel === 'SKU Category'" class="td vs-table--td" style="padding: 5px">
                            <template v-if="index !== 0 && data.sd_id === null">
                                <button @click="removeItem1(index)" class="remove-button">&#45;</button>
                            </template>
                            <template v-else-if="index == 0 && data.sd_id === null" >
                                <div class="button-container">
                                    <button @click="addItem1(index)" class="add-button">&#43;</button>
                                    <vs-button v-if="sourceSku.selectedSourceModel === 'SKU'" color="primary" type="line" title="Add Multi SKU" icon-pack="feather" icon="icon-arrow-right" @click="handlePromptSku()" />
                                    <vs-button v-if="sourceSku.selectedSourceModel === 'SKU Category'" color="primary" type="line" title="Add Multi SKU Category" icon-pack="feather" icon="icon-arrow-right" @click="handlePromptSkuCategory()" />
                                </div>
                            </template>
                        </td>
                      </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>DISCOUNT CONDITION</span>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Discount Model</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full" :class="{ 'set-readonly1': data.sd_id !== null }">
                <v-select
                    v-model="condition.discountModel"
                    :options="condition.discountOptions"
                    placeholder="Select Discount Model"
                    @input="handleDiscountModelChange"
                    required
                />
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Calculation Model</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full" :class="{ 'set-readonly1': data.sd_id !== null }">
                <v-select
                    v-model="condition.calculationModel"
                    :options="condition.calculationOptions"
                    placeholder="Select Discount Model"
                    required
                />
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span># of Level</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full" :class="{ 'set-readonly': data.sd_id !== null }">
                <vs-input v-model="condition.level" class="w-full" @input="updateTables"></vs-input>
            </div>
        </div><br>
        
        <div class="vx-row mb-6" v-for="(table, tableIndex) in tables" :key="tableIndex">
            <div class="vx-col w-full mb-6">
                <div class="input-container">
                    <label for="input1">DISCOUNT TYPE</label>
                    <div :class="{ 'set-readonly1': data.sd_id !== null }">
                        <CustomMSelect
                        :value="table.discountTypeModel"
                        base-url="/api/wms/v1/master/discount-type"
                        label="discount_type"
                        track-by="discount_type"
                        @selected="(v) => { 
                        table.discountTypeModel = v;
                        handlechangediscount(tableIndex) }"
                        :style="{ width: '300px' }"></CustomMSelect>
                    </div>
                    <label for="input2">BASE CALCULATION</label>
                    <div :class="{ 'set-readonly1': data.sd_id !== null }">
                        <v-select
                            v-model="table.baseModel"
                            :options="table.baseOptions"
                            placeholder="Select Discount Model"
                            :disabled="isDiscountTypeAmount()"
                            required
                        />
                    </div>
                    <label for="input3">MAP</label>
                    <div :class="{ 'set-readonly1': data.sd_id !== null || table.mymapClass }">
                        <input type="checkbox" :checked="table.mymap === 1" v-model="table.mymap" />
                    </div>
                    <input type="hidden" v-model="table.levelHeader"/>
                </div>
                <table width="100%" class="vs-table vs-table--tbody-table mb-6">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="3%">No</th>
                            <th width="5%">From</th>
                            <th width="5%">To</th>
                            <th width="5%">HU</th>
                            <th width="5%">% Discount</th>
                            <th width="5%">Per-Unit</th>
                            <th width="5%">Amount</th>
                            <th width="3%">Multiply</th>
                            <template v-if="data.status === 2">
                                <th width="5%">Amended Data</th>
                            </template>
                            <th width="5%"></th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, rowIndex) in table.items" :key="rowIndex" style="padding-bottom: 5px">
                        <td class="td vs-table--td" style="padding: 5px; padding-left: 12px;">
                            {{ rowIndex + 1 }}
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                          <vs-input type="text" v-model="item.from" class="w-full" min="0" :class="{ 'set-readonly': data.sd_id !== null }" @input="validateNumericInput(tableIndex, rowIndex, 'from')"></vs-input>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                          <vs-input type="text" v-model="item.to" class="w-full" min="0" :class="{ 'set-readonly': data.sd_id !== null }" @input="validateNumericInput(tableIndex, rowIndex, 'to')"></vs-input>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                            <div :class="{ 'set-readonly1': data.sd_id !== null }">
                                <CustomMSelectHU :value="item.hu"
                                    :base-url="'/api/wms/v1/master/item-unit/item/multi'"
                                    :selected-item="currentItemId"
                                    :selected-category="currentItemCategoryId"
                                    label="name" track-by="name" 
                                    @selected="(v) => {
                                        item.hu = v;
                                        item.unitId = v.id}">
                                </CustomMSelectHU>
                            </div>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                          <vs-input type="text" v-model="item.discount" class="w-full" min="0" :disabled="isDiscountTypeAmount()" :class="{ 'set-readonly': data.sd_id !== null }" @input="validateNumericInput(tableIndex, rowIndex, 'discount')"></vs-input>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                          <vs-input type="text" v-model="item.unit" class="w-full" min="0" :disabled="isDiscountTypePercentage()" :class="{ 'set-readonly': data.sd_id !== null }" @input="validateNumericInput(tableIndex, rowIndex, 'unit')"></vs-input>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                          <vs-input type="text" v-model="item.amount" @keypress="validateInput($event)" @input="formatAmount(tableIndex, rowIndex)" class="w-full" :disabled="isDiscountTypePercentage()" :class="{ 'set-readonly': data.sd_id !== null }"></vs-input>
                        </td>
                        <td class="td vs-table--td" style="padding: 2px">
                          <input type="checkbox" :checked="item.multiply === 1" v-model="item.multiply" class="w-full" :disabled="isDiscountTypePercentage()" :class="{ 'set-readonly': data.sd_id !== null }"/>
                          <input type="hidden" v-model="item.step"/>
                        </td>
                        <template v-if="data.status === 2">
                            <td class="td vs-table--td" style="padding: 5px">
                                {{ utcToLocal(item.updated_date) }} - {{ data.created_by }}
                            </td>
                        </template>
                        <td class="td vs-table--td" style="padding: 5px">
                            <button @click="removeItem2(tableIndex, rowIndex)" v-if="rowIndex !== 0 && data.sd_id === null" class="remove-button">&#45;</button>
                            <button @click="addItem2(tableIndex, rowIndex)" v-else-if="data.sd_id === null" class="add-button">&#43;</button>
                        </td>
                      </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button v-if="data.sd_id == null && hasPermission('create')" class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
                    <vs-button class="ml-4 mt-2" type="border" color="danger" @click="handleBack()">Back</vs-button>
                </div>
            </div>
        </div>
        <vs-prompt
            class="customPromptWH"
            @close="activePrompt=false"
            :active.sync="activePrompt"
            :title="'Add Multi Warehouse'">
            <div class="con-exemple-prompt">
                <div>
                    <vs-textarea
                        v-model="warehouseCode"
                        placeholder="Enter your warehouse here..."
                    ></vs-textarea>
                </div>
            </div>
            <div>
                <span>Separator format using ;</span><br>
                <span>Example : W3208;W7302;W6403</span>
            </div>
            <div>
                <vs-button color="primary" class="mr-3 mb-2" @click="submitPrompt">Submit</vs-button>
                <vs-button color="danger" class="ml-4 mt-2" @click="activePrompt = false">Cancel</vs-button>
            </div>
        </vs-prompt> 
        <vs-prompt
            class="customPromptWH"
            @close="activePromptSku=false"
            :active.sync="activePromptSku"
            :title="'Add Multi SKU'">
            <div class="con-exemple-prompt">
                <div>
                    <vs-textarea
                        v-model="skuCode"
                        placeholder="Enter your sku here..."
                    ></vs-textarea>
                </div>
            </div>
            <div>
                <span>Separator format using ;</span><br>
                <span>Example : ULI20066269;ULI20068202;ULI20068222</span>
            </div>
            <div>
                <vs-button color="primary" class="mr-3 mb-2" @click="submitPromptSku">Submit</vs-button>
                <vs-button color="danger" class="ml-4 mt-2" @click="activePromptSku = false">Cancel</vs-button>
            </div>
        </vs-prompt> 
        <vs-prompt
            class="customPromptWH"
            @close="activePromptSkuCategory=false"
            :active.sync="activePromptSkuCategory"
            :title="'Add Multi SKU Category'">
            <div class="con-exemple-prompt">
                <div>
                    <vs-textarea
                        v-model="skuCategoryCode"
                        placeholder="Enter your sku category here..."
                    ></vs-textarea>
                </div>
            </div>
            <div>
                <span>Separator format using ;</span><br>
                <span>Example : A0002;A1103</span>
            </div>
            <div>
                <vs-button color="primary" class="mr-3 mb-2" @click="submitPromptSkuCategory">Submit</vs-button>
                <vs-button color="danger" class="ml-4 mt-2" @click="activePromptSkuCategory = false">Cancel</vs-button>
            </div>
        </vs-prompt> 
    </vx-card>
</template>

<script>

import _ from "lodash";
import Warehouse from "@/components/Warehouse.vue";
import Date from "@/components/Date.vue";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import Supplier from "@/components/Supplier.vue";
import CustomMSelect from "@/components/CustomMultiSelect.vue";
import CustomMSelect2 from "@/components/CustomMultiSelect.vue";
import CustomMSelectHU from "./component/CustomMultiSelectHU.vue";
import CustomMSelectSKU from "./component/CustomMultiSelectSKU.vue";
import CustomMSelectWH from "./component/CustomMultiSelectWH.vue";
import CustomMSelectSupplier from "./component/CustomMultiSelectSupplier.vue";
import CustomMSelectCategory from "./component/CustomMultiSelectCategory.vue";

import vSelect from "vue-select";
export default {
    components: {
        Warehouse,
        Date,
        Datepicker,
        vSelect,
        Supplier,
        CustomMSelect,
        CustomMSelect2,
        CustomMSelectHU,
        CustomMSelectSKU,
        CustomMSelectWH,
        CustomMSelectCategory,
        CustomMSelectSupplier,
    },
    data() {
        return {
            currentItemId: [],
            currentItemCategoryId: [],
            data: {
                // selectedWarehouse: null,
                selectedSupplier: null,
                discount_code: null,
                discount_name: null,
                currency_code: null,
                valid_from: null,
                valid_to: null,
                created_by: null,
                created_date: null,
                updated_date: null,
                note: null,
                pd_id: null,
                sd_id: null,
                sdw_id: null,
                file: null,
                status: null,
                fileUrl: '',
            },
            isUpdating: false,
            condition: {
                discountModel: { label: '%', value: '%' },
                discountOptions: [
                    { label: '%', value: '%' },
                    { label: 'Amount', value: 'Amount' },
                ],
                calculationModel: { label: 'Final', value: 'Final' },
                calculationOptions: [
                    { label: 'Step', value: 'Step' },
                    { label: 'Final', value: 'Final' },
                ],
                level: 1,
                conditionId: 0,
            },
            wh: [
                {
                    selectedWarehouse: [],
                }
            ],
            sources: [
                {
                    sourceModel: { label: 'All SKU', value: 'All SKU' },
                    sourceOptions: [
                        { label: 'All SKU', value: 'All SKU' },
                        { label: 'SKU', value: 'SKU' },
                        { label: 'SKU Category', value: 'SKU Category' },
                    ],
                }
            ],
            sourceSkus: [],
            tables: [],
            statusReadonly: false,
            statusTab: null,
            activePrompt: false,
            warehouseCode: null,
            activePromptSku: false,
            skuCode: null,
            activePromptSkuCategory: false,
            skuCategoryCode: null,
            permission: [],
        }
    },
    mounted() {
        this.updateTables();
        // var now = moment()
        // this.data.receive_date = now
        // now.add(1, 'days')

        this.id = this.$route.params.id;
        this.statusTab = this.$route.params.tab;
        if (this.id) { // sto id
            this.getData()
        }
        console.log('update item id',this.currentItemId)
        this.getPermission();
    },
    methods: {
        customSupplier({ code, name }) {
            return `${code} - ${name}`;
        },
        handlePrompt() {
            this.activePrompt = true
        },
        handlePromptSku() {
            this.activePromptSku = true
        },
        handlePromptSkuCategory() {
            this.activePromptSkuCategory = true
        },
        submitPrompt() {
            console.log('Warehouse code submitted:', this.warehouseCode);
            const whcode = encodeURIComponent(this.warehouseCode);
            console.log('Warehouse code:', whcode);
            const url = `${this.$store.state.master.purchaseDiscount.baseUrlPath}/warehouse?warehouse_code=${whcode}`;
            this.$vs.loading();
            this.$http
                .get(url)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.wh[0].selectedWarehouse = resp.data.records.map(el => ({
                            id: el.id,
                            name: el.name,
                        }));
                        console.log('respon',resp)
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
            this.activePrompt = false;
        },
        submitPromptSku() {
            const supplierId = this.data.selectedSupplier ? this.data.selectedSupplier.id : 0;
            console.log('supplier ID:', supplierId);
            const skucode = encodeURIComponent(this.skuCode);
            const url = `${this.$store.state.master.purchaseDiscount.baseUrlPath}/sku?sku_code=${skucode}&supplier_id=${supplierId}`;
            this.$vs.loading();
            this.$http
                .get(url)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.sourceSkus = []
                        console.log("data record",resp.data.records.length)
                        if(resp.data.records.length > 0){
                            resp.data.records.forEach((el, index) => {
                                    var dataitem = el.id
                                    var dataitemname = el.name
                                    var dataitemcode = el.sku_code
                                    const itemSource = {
                                        itemId: {
                                            id : dataitem,
                                            name : dataitemcode +' - '+ dataitemname
                                        },
                                        idItems: dataitem,
                                        selectedSourceModel: 'SKU',
                                    };
                                  this.sourceSkus.push(itemSource)
                                  this.currentItemId.push(dataitem)
                                  console.log('current', this.currentItemId)
                                });
                        } else {
                            const itemSource = {
                                itemId: 0,
                                idItems: 0,
                                selectedSourceModel: 'SKU',
                            };
                            this.sourceSkus.push(itemSource)
                        }
                        console.log('respon',resp)
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
            this.activePromptSku = false;
        },
        submitPromptSkuCategory() {
            const supplierId = this.data.selectedSupplier ? this.data.selectedSupplier.id : 0;
            console.log('SKU code submitted:', this.skuCategoryCode);
            const skucategorycode = encodeURIComponent(this.skuCategoryCode);
            const url = `${this.$store.state.master.purchaseDiscount.baseUrlPath}/sku-category?sku_category_code=${skucategorycode}&supplier_id=${supplierId}`;
            this.$vs.loading();
            this.$http
                .get(url)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.sourceSkus = []
                        if(resp.data.records.length > 0){
                            resp.data.records.forEach((el, index) => {
                                    var dataitem = el.id
                                    var dataitemname = el.name
                                    var dataitemcode = el.code
                                    const itemSource = {
                                        itemId: {
                                            id : dataitem,
                                            name : dataitemcode +' - '+ dataitemname
                                        },
                                        idItems: dataitem,
                                        selectedSourceModel: 'SKU Category',
                                    };
                                this.sourceSkus.push(itemSource)
                                this.currentItemCategoryId.push(dataitem)
                                console.log('current', this.currentItemCategoryId)
                                });
                        } else {
                            const itemSource = {
                                itemId: 0,
                                idItems: 0,
                                selectedSourceModel: 'SKU Category',
                            };
                            this.sourceSkus.push(itemSource)
                        }
                        console.log('respon',resp)
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
            this.activePromptSkuCategory = false;
        },
        validateNumericInput(tableIndex, rowIndex, type) {
            // Hanya biarkan inputan jika hanya angka yang dimasukkan
            if(type == 'from'){
                this.tables[tableIndex].items[rowIndex].from = this.tables[tableIndex].items[rowIndex].from.replace(/\D/g, '');
            } else if(type == 'to'){
                this.tables[tableIndex].items[rowIndex].to = this.tables[tableIndex].items[rowIndex].to.replace(/\D/g, '');
            } else if(type == 'discount'){
                this.tables[tableIndex].items[rowIndex].discount = this.tables[tableIndex].items[rowIndex].discount.replace(/[^0-9.]/g, '');
            } else if(type == 'unit'){
                this.tables[tableIndex].items[rowIndex].unit = this.tables[tableIndex].items[rowIndex].unit.replace(/\D/g, '');
            }
        },
        formatAmount(tableIndex, rowIndex) {
            this.tables[tableIndex].items[rowIndex].amount = this.tables[tableIndex].items[rowIndex].amount.replace(/,/g, "")
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        handleAmount(){
            this.tables.forEach((table, tableIndex) => {
                table.items.forEach((item, rowIndex) => {
                    if (item.amount == 0) {
                        item.amount = parseFloat(item.amount);
                    } else {
                        if (typeof item.amount === 'string') {
                            item.amount = item.amount.replace(/,/g, "").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        } else {
                            item.amount = item.amount.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        }
                    }
                });
            });
        },
        handleDigit(){
            this.tables.forEach((table, tableIndex) => {
                table.items.forEach((item, rowIndex) => {
                    item.to = 0;
                });
            });
        },
        validateInput(event) {
            const charCode = event.which ? event.which : event.keyCode;
            if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
                event.preventDefault();
            }
        },
        addItem(index) {
            const newItem = {
                sourceModel: 'All SKU',
                sourceOptions: [
                    { label: 'All SKU', value: 'All SKU' },
                    { label: 'SKU', value: 'SKU' },
                    { label: 'SKU Category', value: 'SKU Category' },
                ],
            };
            this.sources.splice(index + 1, 0, newItem);
            // console.log('Selected Warehouse ID:', this.wh[0].selectedWarehouse[0].id);
            // console.log('Selected Supplier:', this.sourceSkus[0].itemId.id);

        },
        removeItem(index) {
            if (this.sources.length > 1) {
                this.sources.splice(index, 1);
            }
        },
        deepClone(obj) {
            return JSON.parse(JSON.stringify(obj));
        },
        addItem1(index1) {
            const lastItemIndex = this.sourceSkus.length - 1;
            console.log('index',lastItemIndex)
            const prevSelectedSourceModel = this.sourceSkus[index1].selectedSourceModel;
            
            // Create a deep copy of prevSelectedSourceModel
            const newSelectedSourceModel = JSON.parse(JSON.stringify(prevSelectedSourceModel));

            const itemSource = {
                itemId: {},
                idItems: '',
                selectedSourceModel: newSelectedSourceModel,
            };

            this.sourceSkus.splice(lastItemIndex + 1, 0, itemSource);
        },
        removeItem1(index1) {
            // Remove the row at the specified index
            if (this.sourceSkus.length > 1) {
                this.sourceSkus.splice(index1, 1);
            }
        },
        addItem2(tableIndex, rowIndex) {
            // Add a new row to the specified table
            const isPercentage = this.isDiscountTypePercentage(tableIndex);

            // Create a copy of the items array and push the new item to it
            const newItems = [...this.tables[tableIndex].items, {
            from: 0,
            to: 0,
            hu: '',
            hu_name: '',
            discount: 0,
            unit: 0,
            unitId: '',
            amount: 0,
            multiply: 0,
            discountTypeModel: this.tables[tableIndex].discountTypeModel,
            baseModel: this.tables[tableIndex].baseModel,
            mymap: this.tables[tableIndex].mymap,
            isPercentage: isPercentage,
            }];

            // Use Vue.set to update the items array in a reactive way
            this.$set(this.tables, tableIndex, { ...this.tables[tableIndex], items: newItems });
        },
        removeItem2(tableIndex, rowIndex) {
        // Remove the row at the specified index from the specified table
            if (this.tables[tableIndex].items.length > 1) {
                this.$set(
                this.tables,
                tableIndex,
                Object.assign({}, this.tables[tableIndex], {
                    items: this.tables[tableIndex].items.filter((item, index) => index !== rowIndex),
                })
                );
            }
        },

        // Method to update the number of tables based on the level input
        updateTables() {
            const levelString = String(this.condition.level || '');  // Convert to string or default to empty string
            this.condition.level = levelString.replace(/\D/g, '');
            const numberOfTables = parseInt(this.condition.level, 10) || 0;
            this.tables = new Array(numberOfTables).fill(null).map(() => this.generateTable());
        },
        // Helper method to generate the initial structure of a table
        generateTable() {
            return {
                // discountTypeModel: '',
                discountTypeModel: {discount_type : ''},
                baseModel: 'Original',
                baseOptions: [
                    { label: 'Original', value: 'Original' },
                ],
                mymap: 0,
                levelHeader: '',
                items: [
                    {
                        from: 0,
                        to: 0,
                        // hu: '',
                        hu: {
                                id: 0,
                                name: '',
                            },
                        hu_name: '',
                        discount: 0,
                        unit: 0,
                        unitId: '',
                        amount: 0,
                        multiply: 0,
                        discountTypeModel: this.tables.discountTypeModel,
                        baseModel: this.tables.baseModel,
                        mymap: this.tables.mymap,
                    },
                ],
            };
        },
        updateSource(source, index){
            this.sourceSkus = []
            if (source.sourceModel.value === 'SKU' || source.sourceModel.value === 'SKU Category') {
                this.sourceSkus.push({
                    selectedSourceModel: source.sourceModel.value,
                    itemId: '',
                    idItems: ''
                });
            }
        },
        handlechangediscount(tableIndex) {
            console.log('value include map',this.tables[tableIndex].discountTypeModel)
            if(this.data.sd_id == null){
                if(this.tables[tableIndex].discountTypeModel.include_map == 'YES'){
                    this.$set(this.tables[tableIndex], 'mymap', 1);
                    this.$set(this.tables[tableIndex], 'mymapClass', true); // set untuk disable map
                } else {
                    this.$set(this.tables[tableIndex], 'mymap', 0);
                    this.$set(this.tables[tableIndex], 'mymapClass', false);
                }
            }
        },
        updateDiscountTypeModel(newValue) {
            this.$set(this.tables, 'discountTypeModel', newValue);
        },
        isDiscountTypePercentage() {
            if(this.condition.discountModel.value === '%'){
                this.tables.forEach((table, tableIndex) => {
                    table.items.forEach((item, rowIndex) => {
                        item.unit = 0,
                        item.amount = 0
                    });
                });
            }
            return this.condition.discountModel.value === '%';
        },
        isDiscountTypeAmount() {
            if(this.condition.discountModel.value === 'Amount') {
                this.tables.forEach((table, tableIndex) => {
                    table.items.forEach((item, rowIndex) => {
                        item.discount = 0
                    });
                });
            }
            return this.condition.discountModel.value === 'Amount';
        },

        handleSupplierChange(newSupplier) {
            this.updateItemApiParameter();
            if(!this.isUpdating){
                this.wh[0].selectedWarehouse = [],
                this.sources[0].sourceModel = { label: 'All SKU', value: 'All SKU' },
                this.sourceSkus = [],
                this.condition.discountModel = { label: '%', value: '%' },
                this.condition.calculationModel = { label: 'Final', value: 'Final' },
                this.condition.level = 1,
                this.updateTables();
            }
        },

        handleWarehouseChange(newWarehouses) {
            this.updateItemApiParameter();
        },

        updateItemApiParameter() {
            // Perbarui nilai yang digunakan untuk parameter pada API item
            const supplierId = this.data.selectedSupplier ? this.data.selectedSupplier.id : 0;
            const warehouseIds = this.selectedWarehouse.map(wh => wh.id) || [];

            // Gunakan nilai ini untuk parameter pada API item
            const apiUrl = `/api/wms/v1/master/warehouse/multi/${warehouseIds.join(',')}/items?supplier_id=${supplierId}`;
        },

        generateItemApiUrl() {
            const supplierId = this.data.selectedSupplier ? this.data.selectedSupplier.id : 0;
            return `/api/wms/v1/master/items/supplier/${supplierId}`;
        },

        generateItemCategoryApiUrl() {
            // Generate URL API item berdasarkan nilai supplier dan warehouse saat ini
            const supplierId = this.data.selectedSupplier ? this.data.selectedSupplier.id : 0;
            console.log("Spplier ID",supplierId)
            return `/api/wms/v1/master/item-category/supplier/${supplierId}`;
        },

        handleDiscountModelChange() {
            this.updateDiscountTypeModel(this.condition.discountModel.value);
            if (this.condition.discountModel.value === 'Amount') {
                this.condition.calculationOptions = [
                    { label: 'Final', value: 'Final' },
                ];
                this.tables.forEach((table) => {
                    table.baseModel = '';
                });
            } else {
                this.condition.calculationOptions = [
                    { label: 'Step', value: 'Step' },
                    { label: 'Final', value: 'Final' },
                ];
                this.tables.forEach((table) => {
                    table.baseModel = 'Original';
                });
            }
        },

        getData() {
            console.log('tes',this.$store.state.master.purchaseDiscount.baseUrlPath  + "/" + this.id)
            this.$vs.loading();
            this.$http
                .get(this.$store.state.master.purchaseDiscount.baseUrlPath + "/" + this.id)
                .then((resp) => {
                    console.log('respon',resp)
                    if (resp.code == 200) {
                        var now = moment().toDate();
                        this.data.sd_id = resp.data.sd_id
                        this.data.sdw_id = resp.data.sdw_id
                        this.data.discount_code = resp.data.sd_code
                        this.data.discount_name = resp.data.sd_name
                        this.data.valid_from = resp.data.valid_from == null ? now : resp.data.valid_from
                        this.data.valid_to = resp.data.valid_to == null ? now : resp.data.valid_to
                        this.data.created_by = resp.data.created_by
                        this.data.created_date = resp.data.created_at
                        this.data.updated_date = resp.data.updated_at
                        this.data.currency_code = {
                            code : resp.data.currency_code
                        }
                        this.isUpdating = true;
                        this.data.selectedSupplier = {
                            id : resp.data.supplier_id,
                            name : resp.data.supplier_code +' - '+ resp.data.supplier_name,
                        }
                        this.data.note = resp.data.notes
                        this.data.status = resp.data.status
                        this.condition.discountModel = resp.data.discount_model
                        this.condition.calculationModel = resp.data.calculation_model
                        this.condition.level = resp.data.level
                        this.condition.conditionId = resp.data.sdc_id
                        // this.updateTables();
                        this.fileUrl = resp.data.file_url
                        
                        console.log('warehouse', resp.data.warehouse)
                        this.wh[0].selectedWarehouse = resp.data.warehouse.map(el => ({
                            id: el.warehouse_id,
                            name: el.name,
                            sdw_id: el.id,
                        }));

                        this.tables.splice(0, this.tables.length);

                        var jmllevel = []
                        resp.data.lines.forEach((el, index) => {
                            jmllevel.push(el);
                        });
                        var groupedData = jmllevel.reduce((result, currentItem) => {
                            // Jika belum ada properti dengan step yang sama, buat array kosong
                            if (!result[currentItem.step]) {
                                result[currentItem.step] = [];
                            }
                            // Tambahkan item ke dalam array yang sesuai dengan step-nya
                            result[currentItem.step].push(currentItem);
                            return result;
                        }, {});

                        console.log('Grouped Data', groupedData);
                        console.log('lines',resp.data.lines)
                        this.tables = [];
                        for (const Step in groupedData) {
                            if (Object.prototype.hasOwnProperty.call(groupedData, Step)) {
                                const il = groupedData[Step];
                                const newDiscount = {
                                    discountTypeModel: {discount_type : il[0].discount_type},
                                    discountTypeOptions: [
                                        { label: '%', value: '%' },
                                        { label: 'Amount', value: 'Amount' },
                                    ],
                                    baseModel: il[0].base_calculation,
                                    baseOptions: [
                                        { label: 'Original', value: 'Original' },
                                    ],
                                    mymap: il[0].map,
                                    levelHeader: il[0].step,
                                    items : [],
                                };
                                resp.data.lines.forEach((el, index) => {
                                    if(il[0].step == el.step) {
                                        newDiscount.items.push({
                                            from: el.from_qty,
                                            to: el.to_qty,
                                            hu: {
                                                id: el.unit_id,
                                                name: el.hu,
                                            },
                                            hu_name : el.hu,
                                            unitId: el.unit_id,
                                            discount: el.discount_rate,
                                            unit: el.unit,
                                            amount: el.discount_amount ? el.discount_amount.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "",
                                            multiply: el.multiply,
                                            discountTypeModel: el.discount_type,
                                            baseModel: el.base_calculation,
                                            map: el.map,
                                            lineId: el.sd_line_id,
                                            updated_date: el.updated_at
                                        });
                                    }
                                });                           
                                this.tables.push(newDiscount);
                            }
                        }
                        if(resp.data.groupsource.length > 0){
                            this.sources = [];
                            resp.data.groupsource.forEach((el, index) => {
                                const newItem = {
                                sourceModel: el.source,
                                sourceOptions: [
                                    { label: 'All SKU', value: 'All SKU' },
                                    { label: 'SKU', value: 'SKU' },
                                    { label: 'SKU Category', value: 'SKU Category' },
                                ],
                              }
                              this.sources.push(newItem)
                              this.$set(this.sourceSkus, index, {
                                    ...this.sourceSkus[index],
                                    selectedSourceModel: el.source,
                                });
                            });
    
                            console.log('source', resp.data.source);
                            this.sourceSkus.splice(0, this.sourceSkus.length);
                            resp.data.source.forEach((el, index) => {
                                var dataitem = null
                                var dataitemname = ''
                                var dataitemcode = ''
                                if(el.source == "SKU"){
                                    dataitem = el.item_id,
                                    dataitemname = el.item_name,
                                    dataitemcode = el.sku_code,
                                    this.currentItemId.push(dataitem)
                                }else {
                                    dataitem = el.item_category_id,
                                    dataitemname = el.item_category_name,
                                    this.currentItemCategoryId.push(dataitem)
                                    if(el.item_category_code != null){
                                        dataitemcode = el.item_category_code
                                    }else {
                                        dataitemcode = ''
                                    }
                                }
                                const itemSource = {
                                    itemId: {
                                        id : dataitem,
                                        name : dataitemcode +' - '+ dataitemname
                                    },
                                    idItems: dataitem,
                                    sourceId: el.sds_id,
                                    selectedSourceModel: el.source,
                                };
                              this.sourceSkus.push(itemSource)
                            });
                        } else {
                            const newItem = {
                                sourceModel: 'All SKU',
                                sourceOptions: [
                                    { label: 'All SKU', value: 'All SKU' },
                                    { label: 'SKU', value: 'SKU' },
                                    { label: 'SKU Category', value: 'SKU Category' },
                                ],
                            }
                            this.sources.push(newItem)
                        }
                        this.$nextTick(() => {
                            this.isUpdating = false; // Reset flag setelah DOM update
                        });
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        handleBack() {
            // this.$router.push({
            //     name: this.$store.state.master.purchaseDiscount.baseRouterName,
            // });
            this.$router.push({
                name: "master.purchase-discount",
                params: {status: this.statusTab}
            });
        },
        handleChangeAttachment(event) {
            this.file = event.target.files ? event.target.files[0] : null;
        },
        handleSubmit() {
            let http = null
            const itemIdSet = new Set();
            let hasDuplicate = false;
            let errorMessages = [];
            let errorDigit = [];
            console.log("DATA SOURCE",this.sourceSkus)
            this.sourceSkus.forEach(source => {
                if(source.selectedSourceModel != "All SKU" && (source.itemId == null || source.itemId == undefined || source.itemId.id == 0 || source.itemId.id == null || source.itemId.id == '' || source.itemId.id == undefined)){
                    console.log('TESSSSSSSSS 123123')
                    errorMessages.push(`SKU/SKU Category is required.`);
                }
                if(source.itemId != null && source.itemId.id != undefined){
                    const itemId = source.itemId.id.toString();
                    if (itemIdSet.has(itemId)) {
                        hasDuplicate = true;
                        return;
                    }
                    itemIdSet.add(itemId);
                }
            });

            const validFrom = moment(this.data.valid_from).format('YYYY-MM-DD');
            const validTo = moment(this.data.valid_to).format('YYYY-MM-DD');
            const discountTypeSet = new Set();
            let discountTypeDuplicate = false;
            console.log("DATA TABLES",this.tables)
            this.tables.forEach((table, tableIndex) => {
                if(table.discountTypeModel != null && table.discountTypeModel.discount_type != undefined && table.discountTypeModel.discount_type != ''){
                    const discountType = table.discountTypeModel.discount_type.toString();
                    if (discountTypeSet.has(discountType)) {
                        discountTypeDuplicate = true;
                        return;
                    }
                    discountTypeSet.add(discountType);
                } else {
                    errorMessages.push(`Discount type is required.`);
                }
                
                table.items.forEach((item, rowIndex) => {
                    var lengtFrom = item.from;
                    var lengtTo = item.to;
                    if(item.hu != null){
                        item.hu = {
                                    id: item.hu.id,
                                    name: item.hu.name,
                                },
                        item.hu_name = item.hu.name
                    } else {
                        item.hu = {
                                    id: 0,
                                    name: '',
                                },
                        item.hu_name = ''
                    }
                    console.log('DATA HU',item.hu)
                    item.from = parseFloat(item.from);
                    item.to = parseFloat(item.to);
                    item.discount = parseFloat(item.discount);
                    if(item.amount == 0){
                        item.amount = parseFloat(item.amount);
                    }else {
                        item.amount = parseFloat(item.amount.replace(/,/g, ''));
                    }
                    // item.multiply = item.multiply ? 1 : 0;
                    if(item.multiply == true) {
                        item.multiply = 1;
                    } else if(item.multiply == false){
                        item.multiply = 0;
                    }

                    if(table.mymap == true) {
                        item.mymap = 1;
                    } else if(table.mymap == false){
                        item.mymap = 0;
                    } else {
                        item.mymap = table.mymap;
                    }
                    item.step = tableIndex;
                    if(table.discountTypeModel != null){
                        item.discountTypeModel = table.discountTypeModel.discount_type;
                    }
                    if (table.baseModel !== '') {
                        console.log('TESSSSSSSS')
                        item.baseModel = table.baseModel;
                    } else {
                        item.baseModel = 'kosong';
                    }
                    // Validasi submit
                    const overlappingItem = table.items.find(otherItem => 
                        otherItem !== item && // Jangan membandingkan item dengan dirinya sendiri
                        otherItem.from <= item.to && otherItem.to >= item.from && otherItem.hu.name === item.hu.name
                    );
                    if (item.from > item.to) {
                        errorMessages.push(`From in row ${rowIndex + 1} of table ${tableIndex + 1} cannot be greater than To.`);
                    } else if (overlappingItem) {
                        errorMessages.push(`From and to in rows ${rowIndex + 1} and ${table.items.indexOf(overlappingItem) + 1} in level ${tableIndex + 1} have overlapping ranges.`);
                    } else if(item.from == 0 || isNaN(item.from)){
                        errorMessages.push(`From cannot be 0 or blank.`);
                    } else if(item.to == 0 || isNaN(item.to)){
                        errorMessages.push(`To cannot be 0 or blank.`);
                    } else if(item.hu.id == 0 || item.hu.id == undefined || item.hu == null){
                        errorMessages.push(`HU is required.`);
                    } else if(this.condition.discountModel.value === '%' && (item.discount == 0 || isNaN(item.discount))){
                        errorMessages.push(`Discount cannot be 0 or blank.`);
                    } else if(this.condition.discountModel.value === 'Amount' && (item.unit == 0 || isNaN(item.unit) || item.unit == '')){
                        errorMessages.push(`Unit cannot be 0 or blank.`);
                    } else if(this.condition.discountModel.value === 'Amount' && (item.amount == 0 || isNaN(item.amount))){
                        errorMessages.push(`Amount cannot be 0 or blank.`);
                    } else if (lengtTo.toString().length > 12) {
                        errorDigit.push(`To in row ${rowIndex + 1} of table ${tableIndex + 1} cannot have more than 12 digits.`);
                    } else if (lengtFrom.toString().length > 12) {
                        errorDigit.push(`From in row ${rowIndex + 1} of table ${tableIndex + 1} cannot have more than 12 digits.`);
                    }
                });
            });
            if(this.data.selectedSupplier == null){
                this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: "Supplier is required",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
            } else if (this.wh[0].selectedWarehouse.length == 0) {
                this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: "Warehouse is required",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
            } else if (hasDuplicate) {
                this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: "SKU/SKU Category cannot be the same",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
            } else if(this.data.discount_name == null){
                this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: "Discount name is required",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
            } else if(this.data.valid_from == null){
                this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: "Valid from is required",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
            } else if(this.data.valid_to == null){
                this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: "Valid to is required",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
            } else if(validFrom > validTo){
                this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: "Valid from cannot be greater than valid to",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
            } else if(this.data.currency_code == null){
                this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: "Currency code is required",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
            } else if (discountTypeDuplicate) {
                this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: "Discount type cannot be the same",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
            } else if (errorMessages.length > 0) {
                this.handleAmount()
                errorMessages.forEach(errorMessage => {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: errorMessage,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                });
            } else if (errorDigit.length > 0) {
                this.handleDigit()
                errorDigit.forEach(errorDigit => {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: errorDigit,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                });
            } else {
                this.data.valid_from = validFrom
                this.data.valid_to = validTo
                this.data.currency_code = this.data.currency_code.code
                this.data.selectedSupplier = this.data.selectedSupplier.id
                // this.data.selectedWarehouse = this.data.selectedWarehouser.id
                this.condition.level = parseFloat(this.condition.level);
                this.condition.discountOptions = this.condition.discountOptions.value
                this.condition.discountModel = this.condition.discountModel.value
                this.condition.calculationModel = this.condition.calculationModel.value
                
                this.sources.forEach((x) => {
                    if(x.sourceModel.value == 'All SKU'){
                        this.sourceSkus.push({
                            selectedSourceModel: x.sourceModel.value,
                            itemId: 0,
                            idItems: 0
                        });
                    }
                })
                this.sourceSkus.forEach((source) => {
                    source.itemId = source.itemId.id
                })
                console.log('warehouse',JSON.stringify(this.wh))
                let formData = new FormData();
                formData.append("file", this.file);
                formData.append("data", JSON.stringify(this.data))
                formData.append("condition", JSON.stringify(this.condition))
                formData.append("lines", JSON.stringify(this.tables))
                formData.append("source", JSON.stringify(this.sourceSkus))
                formData.append("warehouse", JSON.stringify(this.wh))
                console.log('lines',JSON.stringify(this.tables))
                console.log('source',JSON.stringify(this.sourceSkus))
                console.log('data',JSON.stringify(this.data))
                console.log('condition',JSON.stringify(this.condition))
                this.$vs.loading();
                if (this.data.sd_id != null) {
                    http = this.$http.put(this.$store.state.master.purchaseDiscount.baseUrlPath + "/" + this.data.sd_id, formData)
                } else {
                    http = this.$http.post(this.$store.state.master.purchaseDiscount.baseUrlPath, formData)
                }
                http.then((resp) => {
                console.log(resp)
                if (resp.code == 200) {
                    this.$vs.notify({
                        color: "success",
                        title: "Success",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });

                    this.$vs.loading.close();
                    this.draw++;
                    this.handleBack()
                } else {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                }
                }).catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });
            }
        },
        utcToLocal(val) {
            if (val) {
                return moment(val)
                    .add(-7, "h")
                    .utc()
                    .local()
                    .format("DD MMM YYYY HH:mm:ss");
            }
        },
        openTab(uri) {
            window.open(uri)
        },
        getPermission() {
            this.$vs.loading();
            this.$http
                .get("/api/account/v1/permission", {
                params: {
                    module: "purchase-discount",
                    source: "WMS"
                }
                })
                .then((resp) => {
                this.$vs.loading.close();
                if (resp.code === 200) {
                    this.permission = resp.data;
                } else {
                    this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: resp.message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                    });
                }
            });
        },
    },
    computed: {
        formatDate: () => {
            return (val) => {
                if (!val) {
                    return ''; // If created_date is empty, return an empty string
                }

                const momentDate = moment.utc(val);

                if (!momentDate.isValid()) {
                    return ''; // If the date is invalid, return an empty string
                }

                return momentDate.format('DD MMM YYYY');
                // const momentDate = moment.utc(val);
                // return momentDate.format('DD MMM YYYY');
            };
        },
        discountTypeModels() {
            return this.tables.map(table => table.discountTypeModel || ''); // Default to an empty string if undefined
        },
        selectedWarehouse() {
            // Return the selectedWarehouse array
            return this.wh[0].selectedWarehouse;
        },
        isAllSKUSelected() {
            // Check if "All SKU" is selected in any of the sources
            return this.sources.some(source => source.sourceModel.value === "All SKU");
        },
        hasPermission() {
            return (guardName) => {
                return this.permission.some((perm) => perm.GuardName === guardName);
            };
        },
    },
    watch: {
        'data.selectedSupplier': 'handleSupplierChange',
        'wh[0].selectedWarehouse': 'handleWarehouseChange',
        currentItemId(newValue) {
        // Panggil fungsi atau lakukan tindakan dengan nilai yang baru
            console.log('value terbaru', newValue)
            this.currentItemId = newValue;
            this.$emit('update:selected-item', newValue);
        },
        currentItemCategoryId(newValue) {
        // Panggil fungsi atau lakukan tindakan dengan nilai yang baru
            console.log('value terbaru', newValue)
            this.currentItemCategoryId = newValue;
            this.$emit('update:selected-item', newValue);
        },
    }
}
</script>