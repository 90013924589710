<template>
    <div>
        <vs-table search stripe border description :sst="true" :data="table.data" :max-items="table.length"
            :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div
                        class="flex items-center justify-between p-3 font-medium border border-solid rounded-full cursor-pointer d-theme-border-grey-light d-theme-dark-bg">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of
                            {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </template>
            <template slot="thead">
                <vs-th>Action</vs-th>
                <vs-th sort-key="">InterWarehouse ID</vs-th>
                <vs-th sort-key="">Transaction Date</vs-th>
                <vs-th sort-key="">Arrival Date</vs-th>
                <vs-th sort-key="">Source Warehouse</vs-th>
                <vs-th sort-key="">GR Data</vs-th>
                <vs-th sort-key="">#Of SKU</vs-th>
                <vs-th sort-key="">Total Qty [HU/Qty]</vs-th>
                <vs-th sort-key="">Pass </vs-th>
                <vs-th sort-key="">Damage</vs-th>
                <vs-th sort-key="">Expire</vs-th>
            </template>
            <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                        <div class="flex vx-input-group">
                            <vs-button size="small" color="warning" icon-pack="feather" icon="icon-edit" title="Edit" v-if="$store.getters['user/hasPermissions']('edit')"
                                style="margin-right: 5px" @click="handleCreate(tr.id)" />
                            <vs-button size="small" v-if="tr.id" color="grey" icon-pack="feather"
                                icon="icon-printer" title="Print" style="margin-right: 5px"
                                @click="handlePrint(tr.id)" />
                            <vs-button v-if="(tr.lines[0].putaway).length != 0 && $store.getters['user/hasPermissions']('edit')" size="small" color="success" icon-pack="feather"
                                icon="icon-check" title="Confirm" @click="changeStatus(tr.id)" />
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            {{ tr.stock_relocation_code }}
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            {{ formatDate(tr.transaction_date) }}
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div>{{ formatDate(tr.arrival_date) }}</div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div>{{ tr.from_warehouse.name }}</div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div>{{ tr.code }}</div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div v-if="tr.hu_conv_count > 0" class="flex flex-col content-center">
                            <div>{{ tr.hu_conv_count }}</div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div v-for="(qty, key) in tr.hu_conv" :key="key" class="flex flex-col content-center">
                            <span v-if="tr.hu_conv_count > 0">{{ qty }} {{ key }}</span>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div v-for="(qty, key) in tr.hu_pass" :key="key" class="flex flex-col content-center">
                            <span>{{ qty }} {{ key }}</span>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div v-for="(qty, key) in tr.hu_damage" :key="key" class="flex flex-col content-center">
                            <span>{{ qty }} {{ key }}</span>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div v-for="(qty, key) in tr.hu_expire" :key="key" class="flex flex-col content-center">
                            <span>{{ qty }} {{ key }}</span>
                        </div>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />
    </div>
</template>
<script>
import moment from "moment";
export default {
    components: {},
    props: {
        draw: {
            type: Number,
        },
        sourceWarehouseId: {
            type: Number,
        },
        poTypeId: {
            type: Number,
        },
        supplierId: {
            type: Number,
        },
        transactionDate: {
            type: Date,
            default: null,
        },
        rangedDate: {
            default: null,
        },
        status: {
            type: String,
        },
    },
    data() {
        return {
            deleteId: null,
            table: this.tableDefaultState(),
            checkedAll: false,
            checked: [],
            selectedRouteAssignmentToAssign: [],
            timeOuts: null,
        };
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            if (this.timeOuts) { clearTimeout(this.timeOuts) }
            this.timeOuts = setTimeout(this.getData, 900);
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        getData() {
            this.$vs.loading();
            var params = {
                limit: this.table.length,
                page: this.table.page,
                query: this.table.search,
                order: this.table.order,
                sort: this.table.sort,
                warehouse_id: this.sourceWarehouseId,
                supplier_id: this.supplierId,
                po_type_id: this.poTypeId,
                status: this.status,
            }
            if (this.rangedDate) {
                params.start_date = this.rangedDate.startDate;
                params.end_date = this.rangedDate.endDate;
            }
            this.$http
                .get(this.$store.state.inbound.interWarehouse.baseUrlPath + "/receive", {
                    params: params,
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        this.checkedAll = false;
                        this.checked = [];
                        this.selectedRouteAssignmentToAssign = [];

                        this.table.total = resp.data.total_rows;
                        this.table.totalPage = resp.data.total_pages;
                        this.table.totalSearch = resp.data.total_record_search;
                        this.table.length = resp.data.limit;
                        var rows = resp.data.rows;
                        if (rows) {
                            rows.forEach((row, ir) => {
                                const hu_conv = new Map();
                                const hu_pass = new Map()
                                const hu_damage = new Map()
                                const hu_expire = new Map()
                                var hu_conv_count = 0;
                                row.lines.forEach(item => {
                                    hu_conv.set(item.source_warehouse_unit, item.source_warehouse_qty);

                                    item.conversions.forEach(conv => {
                                        // if (hu_conv.has(conv.unit)) {
                                        //     hu_conv.set(conv.unit, Number(hu_conv.get(conv.unit)) + Number(conv.quantity))
                                        // } else {
                                        //     hu_conv.set(conv.unit, conv.quantity);
                                        // }

                                        if (conv.status_damage == 0) {
                                            if (hu_pass.has(conv.unit)) {
                                                hu_pass.set(conv.unit, Number(hu_pass.get(conv.unit)) + Number(conv.quantity))
                                            } else {
                                                hu_pass.set(conv.unit, conv.quantity);
                                            }
                                        }else if (conv.status_damage == 1) {
                                            if (hu_damage.has(conv.unit)) {
                                                hu_damage.set(conv.unit, Number(hu_damage.get(conv.unit)) + Number(conv.quantity))
                                            } else {
                                                hu_damage.set(conv.unit, conv.quantity);
                                            }
                                        }else if (conv.status_damage == 2) {
                                            if (hu_expire.has(conv.unit)) {
                                                hu_expire.set(conv.unit, Number(hu_expire.get(conv.unit)) + Number(conv.quantity))
                                            } else {
                                                hu_expire.set(conv.unit, conv.quantity);
                                            }
                                        }
                                    });
                                    hu_conv_count++;
                                });
                                rows[ir].hu_conv = Object.fromEntries(hu_conv);
                                rows[ir].hu_damage = Object.fromEntries(hu_damage);
                                rows[ir].hu_pass = Object.fromEntries(hu_pass);
                                rows[ir].hu_expire = Object.fromEntries(hu_expire);
                                rows[ir].hu_conv_count = hu_conv_count
                            });
                        }
                        this.table.data = rows;
                        this.setStartEnd();
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        setStartEnd() {
            let valStart =
                this.table.length * this.table.page - this.table.length + 1;

            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }
            let valEnd = this.table.length * this.table.page;

            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (
                this.table.totalSearch < this.table.total &&
                this.table.search != ""
            ) {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        changeStatus(id) {
            this.deleteId = id;
            this.$vs.dialog({
                type: "confirm",
                title: `Confirm`,
                text: "Please confirm to change status on Completed",
                accept: () => {
                    this.$vs.loading();
                    this.$http.patch(`${this.$store.state.inbound.interWarehouse.baseUrlPath}/receive/${id}/status/oncompleted`)
                        .then((resp) => {
                            this.$vs.loading.close();
                            if (resp.code == 200) {
                                this.$vs.notify({
                                    color: "success",
                                    title: "Success",
                                    text: "Successfully changed status to OnCompleted, data will available in the completed tab",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                                this.getData();
                            } else {
                                this.$vs.notify({
                                    color: "danger",
                                    title: "Error",
                                    text: resp.message,
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            }
                        });
                },
            });
        },
        handleCreate(srrId) {
            this.$router.push({
                name: this.$store.state.inbound.interWarehouse.baseRouterName + ".put-away",
                params: { id: srrId },
            });
        },
        handlePrint(id) {
            this.$router.push({
                name: this.$store.state.inbound.interWarehouse.baseRouterName + ".print-put-away",
                params: { id: id },
            });
        },
        utcToLocal(val) {
            if (val) {
                return moment(val)
                    .add(-7, "h")
                    .utc()
                    .local()
                    .format("DD MMM YYYY HH:mm:ss");
            }
        },
    },
    mounted() {
        this.$store.dispatch("user/getPermissions", "inter-warehouse");
    },
    watch: {
        draw() {
            this.getData();
        },
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('YYYY-MM-DD');
            };
        },
        formatLocalNumber: () => {
            return (val) => {
                return Number(val).toLocaleString("id-ID");
            };
        },
    },
};
</script>
  