<template>
  <div class="vx-row mb-12">
    <div v-bind:class="[detail ? detailShow : '', detailHide]">
      <vs-table
        search
        stripe
        border
        description
        v-model="itemSelected"
        :sst="true"
        :data="table.data"
        :max-items="table.length"
        :total="table.total"
        @change-page="handleChangePage"
        @search="handleSearch"
        @sort="handleSort"
      >
        <template slot="header">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2"
                >{{ this.table.start }} - {{ this.table.end }} of
                {{ this.table.total }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item
                v-for="item in table.limits"
                :key="item"
                @click="handleChangelength(item)"
              >
                <span>{{ item }}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </template>
        <template slot="thead">
          <!-- <vs-th
                    ><vs-checkbox
                      v-model="selectAll"
                      @input="handleSelectAll"
                    />ALL</vs-th
                  > -->

          <vs-th>Select</vs-th>
          <vs-th sort-key="code">Shipment Number</vs-th>
          <vs-th sort-key="name">Driver</vs-th>
          <vs-th sort-key="name">Vehicle Number</vs-th>
          <vs-th sort-key="name">Delivery Date</vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <feather-icon
                icon="EyeIcon"
                class="cursor-pointer mr-1 mt-1"
                svgClasses="w-4 h-4"
                v-if="hasPermission('edit')"
                @click="viewDetail(tr.delivery_orders, tr)"
              />
            </vs-td>

            <vs-td>
              {{ tr.shipment_number }}
            </vs-td>
            <vs-td>
              {{ tr.driver_name }}
            </vs-td>
            <vs-td>
              {{ tr.vehicle_number }}
            </vs-td>
            <vs-td>
              {{ tr.delivery_plan_date | formatDate }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination
        style="padding-top: 5px"
        :total="table.totalPage"
        v-model="setPage"
      />
    </div>
    <!-- detail delivery orders -->
    <transition name="detail-fade">
      <div
        v-if="detail"
        v-bind:class="[detail ? detailShowV2 : '', detailHide]"
      >
        <vs-row>
          <vs-col
            style="padding-bottom: 8px"
            vs-offset="8"
            vs-type="flex"
            vs-justify="rigth"
            vs-align="rigth"
            vs-w="4"
          >
            <vs-button
              class="ml-auto"
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
              >Close</vs-button
            >
          </vs-col>

          <!-- form component -->
          <viewEndSession
            @close="handleClose"
            :dataViews="dataViews"
            :data="data"
            :isNew="false"
          ></viewEndSession>
        </vs-row>
      </div>
    </transition>
  </div>
</template>

<script>
import moment from "moment";
import viewEndSession from "../viewEndSession.vue";
export default {
  components: {
    viewEndSession,
  },
  props: {
    transportPlanID: {
      type: Number,
      default: null,
    },
    warehouseID: {
      type: Number,
      default: null,
    },
    driverName: {
      type: String,
      default: null,
    },
    dateStart: {
      type: String,
      default: "",
    },
    dateEnd: {
      type: String,
      default: "",
    },
    selectedStatus: {
      type: Object,
      default: null,
    },
    isFilters: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.getDatas();
    this.getPermission();
  },

  data() {
    return {
      detail: false,
      detailShow: "core vx-col md:w-1/4 w-full mb-base ",
      detailShowV2: "core vx-col md:w-3/4 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      itemSelected: [],
      deleteId: null,
      table: this.tableDefaultState(),
      transportPlan: [],
      exportID: [],
      IDwasGenerate: [],
      showingData: true,
      selectAll: false,
      cartPODConfirm: [],
      cartTransportPlan: [],
      IDItem: [],
      permission: [],
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },

    handleSearch(searching) {
      this.table.search = searching;
      if (searching == "") {
        this.table.length = 10;
      }
      this.table.page = 1;
      this.getDatas();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getDatas();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getDatas();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getDatas();
    },
    handleClose() {
      this.detail = false;
      this.dataViews = [];
      this.getDatas();
    },
    viewDetail(item, dt) {
      this.dataViews = [];
      this.data = [];
      this.data = this.detail = true;
      this.dataViews = item;
      this.data = dt;
    },

    getDatas() {
      console.log(">>>>>get data", this.driver);
      this.$vs.loading();
      this.table.data = [];
      this.$http
        .get("/api/wms/v1/proof-of-delivery/end-session", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            transport_plan_id: this.transportPlanID,
            warehouse_id: this.$store.getters["pod/pod/getWarehouseID"],
            date_from: this.dateStart,
            date_to: this.dateEnd,
            driver_name: this.driverName ? this.driverName : null,
            status_delivery: this.selectedStatus
              ? this.selectedStatus.id
              : null,
            status: this.status,
          },
        })
        .then((resp) => {
          console.log("rrrrrrr", resp);
          if (resp.code == 200) {
            if (resp.data.records != null) {
              this.table.data = [];
              this.table.total = resp.data.total_record;
              this.table.totalPage = resp.data.total_page;
              this.table.totalSearch = resp.data.total_record_search;
              this.table.length = resp.data.total_record_per_page;
              this.table.data = resp.data.records;
              this.$emit("isFilters", false);
              this.setStartEnd();
            } else {
              this.table.data = [];
              this.table.total = 0;
              this.table.totalPage = 0;
              this.table.totalSearch = 0;
              this.table.length = 0;
              this.table.data = [];
              this.setStartEnd();
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleClickCheckBox() {
      console.log(">>>>iiii", this.cartPODConfirm.id);
      this.$emit("selected-items", this.cartPODConfirm);
    },

    SubmitForm() {
      console.log(">>>>>ini submit", this.itemSelected);
      if (this.itemSelected.length == 0) {
        this.$vs.notify({
          title: "Error",
          text: "Please select Item Line first",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return true;
      }

      for (let index = 0; index < this.itemSelected.length; index++) {
        let items = {};
        items.id = this.itemSelected[index].pod_id;
        items.status = 2;

        this.IDItem.push(items);
      }
      for (let index = 0; index < this.IDItem.length; index++) {
        if (this.IDItem[index].id == 0) {
          this.$vs.notify({
            title: "Error",
            text: "You Have delivery order does't set STATUS POD",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
          this.itemSelected = [];
          this.IDItem = [];
          return true;
        }
      }
      this.$vs.loading();
      this.$http
        .post("/api/wms/v1/proof-of-delivery/process", {
          pod_process: this.IDItem,
        })
        .then((resp) => {
          console.log(resp);
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "Process Success",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.getDatas();
            this.$vs.loading.close();
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.getDatas();
            this.$vs.loading.close();
          }

          this.$vs.loading.close();
        });
    },
    handleEdit(data) {
      console.log("data", data);
      this.$vs.loading();
      this.$router.push({
        name: "proof-of-delivery-edit",
        query: {
          id: data.id,
          action: "Edit",
        },
      });
      this.$vs.loading.close();
    },
    getPermission() {
      this.$vs.loading();
      this.$http
        .get("/api/account/v1/permission", {
          params: {
            module: "saf-execute",
            source: "WMS",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code === 200) {
            this.permission = resp.data;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        });
    },
  },
  watch: {
    detail() {},
    isFilters(val) {
      if (val) {
        this.getDatas();
        this.$emit("isFilters", false);
      }
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
    hasPermission() {
      return (guardName) => {
        return this.permission.some((perm) => perm.GuardName === guardName);
      };
    },
  },
  filters: {
    formatDate(val) {
      return moment(val).add(-7, "h").utc().local().format("DD MMM YYYY");
    },
  },
};
</script>
<style>
.bullet-list {
  list-style-type: disc; /* Atur tipe tanda (dalam hal ini tanda titik) */
  padding-left: 20px; /* Atur jarak kiri untuk membuat tanda tampak lebih baik */
}
</style>
