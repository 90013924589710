<template>
  <vx-card>
    <div>
      <div class="vx-col mb-6 w-1/2"></div>
      <vs-table stripe border description :sst="true" :data="table.data">
        <!-- <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{ this.table.start }} - {{ this.table.end }} of
                  {{ this.table.total }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in table.limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{ item }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template> -->
        <template slot="thead">
          <vs-th sort-key="number">Vehicle Number</vs-th>
          <vs-th sort-key="zone_name">Zone Operation</vs-th>
          <vs-th sort-key="name">Driver Name</vs-th>
          <vs-th sort-key="name">Availability</vs-th>
          <vs-th sort-key="name">Lock Planning</vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].number">
              {{ data[indextr].number }}
            </vs-td>
            <vs-td :data="data[indextr].zone_name">
              {{ data[indextr].zone_name }}
            </vs-td>
            <vs-td>
              <div class="vx-col sm:w-4/5 w-full">
                <multiselect
                  class="selectExample"
                  v-model="table.data[indextr].selectedDriver"
                  :options="optionalDriver"
                  :multiple="false"
                  :allow-empty="false"
                  :group-select="false"
                  :max-height="100"
                  :limit="3"
                  track-by="id"
                  :disabled="data[indextr].lock_planning"
                  placeholder="Type to search"
                  :searchable="true"
                  label="name"
                  @search-change="handleSearchDriver"
                />
              </div>
            </vs-td>
            <vs-td>
              <div class="center con-switch">
                <vs-switch
                  v-model="data[indextr].availability"
                  :disabled="data[indextr].lock_planning"
                >
                </vs-switch>
              </div>
            </vs-td>
            <vs-td>
              <div class="center con-switch">
                <vs-switch v-model="data[indextr].lock_planning" disabled />
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <!-- <vs-pagination
          style="padding-top: 5px"
          :total="table.totalPage"
          v-model="setPage"
        /> -->
      <div class="vx-row flex justify-between mt-5 m-10">
        <div>
          <vs-button
            class="mb-2"
            v-on:click="SubmitForm()"
            v-if="hasPermission('edit')"
            >Save</vs-button
          >
        </div>
        <!-- <div>
          <vs-button class="mb-2" v-on:click="handleClose()">Close</vs-button>
        </div> -->
      </div>
    </div>
  </vx-card>
</template>

<script>
import moment from "moment";
export default {
  components: {},
  props: {
    filterActive: {
      type: Boolean,
    },
    selected: {
      type: Object,
    },
    // data: {
    //   type: Array,
    // },
    // optionalDrivers: {
    //   type: Array,
    // },
    warehouseID: {
      type: Number,
    },
    date: {
      type: String,
    },
    permission: {
      type: Object,
    },
  },

  mounted() {
    // this.getDriver();
    // this.getData();
    // this.checkFilter();
  },

  data() {
    return {
      table: this.tableDefaultState(),
      // date: "",
      optionalDriver: [],
      selectedDriver: { name: "", id: null },
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    checkFilter() {
      console.log(">>>>>ini check filter", this.filterActive);
      if (this.filterActive) {
        console.log(">>>>>ini check filter", this.filterActive);
        this.getDriver();
        // this.getData();
      }
    },

    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/driver-availability/edit", {
          params: {
            warehouse_id: this.warehouseID,
            date: this.date,
            is_history: false,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.table.data = [];

            if (resp.data.records != null) {
              for (let index = 0; index < resp.data.records.length; index++) {
                var tempData = {};
                tempData.availability =
                  resp.data.records[index].is_availibility;
                tempData.id = resp.data.records[index].vehicle_id;
                tempData.number = resp.data.records[index].vehicle_name;
                tempData.zone_name = resp.data.records[index].zone_name;
                tempData.driver_id = resp.data.records[index].driver_id;
                tempData.date_from = resp.data.records[index].date_from;
                tempData.date_to = resp.data.records[index].date_to;
                tempData.total_do = resp.data.records[index].total_do;
                tempData.driver_name = resp.data.records[index].personal_name;
                tempData.indextr = index;
                tempData.lock_planning = resp.data.records[index].is_locked;
                tempData.selectedDriver = {};

                this.optionalDriver.forEach((element) => {
                  if (element.id == tempData.driver_id) {
                    tempData.selectedDriver = element;
                  }
                });

                this.table.data.push(tempData);
              }

              this.date = resp.data.item.date;
            } else {
              this.$vs.notify({
                title: "Error",
                text: `Vehicle/Driver availability do not exist, please generate first`,
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
          this.$emit("checkFilter", false);
        });
    },
    SubmitForm() {
      console.log(">>>>>ini submit", this.table.data);

      //check if driver have 2 or more schedule vehicle in same time then set error
      let driverCountMap = {};
      let driverError = false;
      let driverErrorList = [];
      let driverErrorListName = [];

      // Count the schedules for each driver
      this.table.data.forEach((schedule, index) => {
        let driverName = schedule.selectedDriver.name;
        console.log(">>>>>ini driver", index, driverName);

        if (schedule.selectedDriver.name != "") {
          // Increment the count of schedules for the current driver
          driverCountMap[index] = (driverCountMap[index] || 0) + 1;

          console.log(">>>>>ini driverCountMap", driverCountMap);

          // If the current driver has 2 or more schedules, set error
          if (driverCountMap[index] > 1) {
            driverError = true;
            driverErrorList.push(schedule.selectedDriver);
            driverErrorListName.push(driverName);
            return false;
          }
        }
      });

      if (driverErrorList.length > 1) {
        driverErrorListName = driverErrorListName.slice(0, 2);
        driverErrorListName.push("...");
      } else {
        driverErrorListName = driverErrorListName.join(", ");
      }

      // // If there is an error, notify the user and return
      if (driverError) {
        this.$vs.notify({
          title: "Error",
          text: `Driver ${driverErrorListName} have 2 or more schedules in the same time`,
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return;
      }

      let submitItem = [];

      for (let index = 0; index < this.table.data.length; index++) {
        let dataSubmit = {};
        dataSubmit.vehicle_id = this.table.data[index].id;
        dataSubmit.is_availibility = this.table.data[index].availability;
        dataSubmit.driver_id = this.table.data[index].selectedDriver
          ? this.table.data[index].selectedDriver.id
          : null;
        dataSubmit.vehicle_name = this.table.data[index].number;
        dataSubmit.zone_name = this.table.data[index].zone_name;

        submitItem.push(dataSubmit);
      }

      console.log(">>>>>ini submit", submitItem);

      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Are you sure to Process this data?",
        accept: () => {
          this.$vs.loading();
          this.$http
            .put("/api/wms/v1/driver-availability/edit", {
              vehicle_availability: submitItem,
              warehouse_id: this.warehouseID,
              date: this.selected.date,
            })
            .then((resp) => {
              console.log(resp);
              if (resp.code == 200) {
                this.$vs.notify({
                  title: "Success",
                  text: "Reservation has been added",
                  color: "success",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-check",
                });
              } else {
                this.$vs.notify({
                  title: "Error",
                  text: resp.message,
                  color: "danger",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-check",
                });
              }
              // this.handleClose();
              this.$vs.loading.close();
            });
        },
      });
    },

    getDriver() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/master/drivers", {
          params: {
            length: 999,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            warehouse_id: this.warehouseID,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionalDriver = resp.data.records;
              if (this.optionalDriver.length > 0) {
                // this.selectedDriver = this.optionalDriver[0];
              } else {
                this.optionalDriver = [];
                this.selectedDriver = {};
              }
            } else {
              this.optionalDriver = [];
              this.selectedDriver = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
          this.getData();
        });
    },
    handleSearchDriver(query) {
      if (query != "") {
        this.table.search = query;

        this.getDriver();
      }
    },
    // customLabelDriver({ name }) {
    //   return `${name}`;
    // },
    // handleClose() {
    //   // back to list
    //   this.$vs.loading();
    //   this.$router.push({ name: "vehicle-availability" });
    //   this.$vs.loading.close();
    // },
  },
  watch: {
    //check if filterActive change
    filterActive: function (val) {
      this.checkFilter();
    },
  },
  computed: {
    hasPermission() {
      return (guardName) => {
        return this.permission.some((perm) => perm.GuardName === guardName);
      };
    },
  },
  filters: {},
};
</script>

<style>
.vs-table--tbody-table
  .tr-values:not(.activeEdit):not(.tr-expandedx):not(.hoverFlat):hover {
  -webkit-transform: none;
  transform: none;
}
</style>
