<template>
  <div>
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="getData.data"
      :max-items="getData.length"
      :total="getData.total"
      @change-page="handleChangePage"
      @search="handleSearch"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ this.getData.start }} - {{ this.getData.end }} of
              {{ this.getData.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template slot="thead">
        <!-- <vs-th>Edit</vs-th> -->
        <vs-th sort-key="code">Warehouse</vs-th>
        <vs-th sort-key="name">Date</vs-th>
        <vs-th sort-key="name"># Of Vehicle</vs-th>
        <vs-th sort-key="name"># of Available Vehicle</vs-th>
      </template>
      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <!-- <vs-td>
            <feather-icon
              icon="EditIcon"
              svgClasses="w-5 h-5 hover:text-primary stroke-current"
              class="ml-4"
              @click="handleEdit(tr)"
            />
          </vs-td> -->
          <vs-td :data="data[indextr].warehouse_name">
            {{ data[indextr].warehouse_name }}
          </vs-td>
          <vs-td :data="data[indextr].date">
            {{ data[indextr].date | formatDate }}
          </vs-td>
          <vs-td :data="data[indextr].total_vehicle">
            {{ data[indextr].total_vehicle }}
          </vs-td>
          <vs-td :data="data[indextr].total_vehicle_available">
            {{ data[indextr].total_vehicle_available }}
          </vs-td>
          <!-- detail delivery orders -->
          <template slot="expand">
            <div class="vx-row" :style="{ overflow: 'auto' }">
              <div class="vx-col">
                <vs-table
                  :style="{ width: '100%' }"
                  :sst="false"
                  :data="tr.driver_availabilities"
                  :searchable="false"
                >
                  <template slot="thead">
                    <vs-th>Vehicle Number</vs-th>
                    <vs-th>Driver Name</vs-th>
                  </template>

                  <template slot-scope="{ data }">
                    <vs-tr
                      :data="tr"
                      :key="indextr"
                      v-for="(tr, indextr) in data"
                    >
                      <vs-td>
                        <b>{{ tr.vehicle_number }}</b>
                      </vs-td>
                      <vs-td>
                        <b>{{ tr.driver_name }}</b>
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </div>
            </div>
          </template>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="getData.totalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
import moment from "moment";
export default {
  components: {},
  props: {
    filterActive: {
      type: Boolean,
    },
    getData: {
      type: Object,
    },
    permission: {
      type: Object,
    },
  },
  data() {
    return {
      table: this.tableDefaultState(),
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleEdit(data) {
      console.log("ininnnnnnnnn", data);
      this.$emit("handleEdit", data);
    },
    handleSearch(searching) {
      this.$emit("handleSearch", searching);
    },
    handleChangePage(page) {
      this.$emit("handleChangePage", page);
    },
    handleSort(key, active) {
      this.$emit("handleSort", key, active);
    },
    handleChangelength(val) {
      this.$emit("handleChangelength", val);
    },
  },
  filters: {
    formatDate(val) {
      return moment(val).add(-7, "h").utc().local().format("DD MMM YYYY");
    },
  },
  computed: {
    setPage: {
      get() {
        return this.getData.page;
      },
      set(val) {
        this.$emit("handleChangePage", val);
      },
    },
    hasPermission() {
      return (guardName) => {
        return this.permission.some((perm) => perm.GuardName === guardName);
      };
    },
  },
};
</script>
