<template>
    <vx-card :title="`Receive Completed`">
        <div class="mb-6 vx-row">
            <vs-button @click="handleBack()" class="mt-2 ml-4" color="danger" icon-pack="feather"
                icon="icon-arrow-left">Back</vs-button>
        </div>
        <div class="flex md:flex-row">
            <div class="w-full pr-6 md:basis-1/2">
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Inter Warehouse ID</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <vs-input :value="form.sto_code" disabled :readonly="true"
                            class="w-full bg-grey-light"></vs-input>
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Source Warehouse</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <vs-input :value="form.src_warehouse.code + ' - ' + form.src_warehouse.name" disabled
                            :readonly="true" class="w-full bg-grey-light"></vs-input>
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Arrival Date</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <datepicker name="receive_date" format="yyyy-MM-dd" class="w-full bg-grey-light" disabled :inline="false"
                            v-model="form.receive_date" placeholder="Select Date" :cleared="() => {
                            form.receive_date = null
                        }">
                        </datepicker>
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>GR Document Number</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <vs-input :value="form.code" disabled :readonly="true" class="w-full bg-grey-light"></vs-input>
                    </div>
                </div>
            </div>
            <div class="w-full md:basis-1/2">
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Shipment Number</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <vs-input v-model="form.shipment_number" class="w-full bg-grey-light" disabled />
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Delivery Note Number</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <vs-input v-model="form.delivery_note_number" class="w-full bg-grey-light" disabled />
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Delivery Note Date</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <datepicker name="delivery_date" format="yyyy-MM-dd" class="w-full bg-grey-light" disabled :inline="false"
                            v-model="form.delivery_date" placeholder="Select Date" :cleared="() => {
                            form.delivery_date = null
                        }">
                        </datepicker>
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Attachment</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6 upload">
                        <vs-button class="ml-5" v-if="form.attachment && form.attachment.length > 0"
                            @click="popShow = true" color="primary" type="filled">Download</vs-button>
                        <span v-else> - No attachment - </span>
                        <vs-popup classContent="popup-example" title="Download or delete" :active.sync="popShow">
                            <vs-list>
                                <vs-list-item v-for="(item, index) in form.attachment" :key="index" :title="item.name"
                                    class="flex">
                                    <div class="flex">
                                        <a @click.stop="downloadFileAwsS3(item.path)"><vs-button color="success" type="flat"
                                            icon="file_download"></vs-button></a>
                                    </div>
                                </vs-list-item>
                            </vs-list>
                        </vs-popup>
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-6 vx-row">
            <div class="w-full">
                <table width="100%" v-if="this.rerendr" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th colspan="2"></th>
                            <th colspan="2">Conversion</th>
                            <th colspan="4">Batch/ED</th>
                            <th colspan="2">Put Away</th>
                        </tr>
                        <tr>
                            <th width="7%">
                                <div>SKU Code</div>
                            </th>
                            <th width="20%">
                                <div>SKU Description</div>
                            </th>
                            <th width="5%">HU</th>
                            <th width="5%">Qty</th>
                            <th width="5%">Qty</th>
                            <th width="10%">Batch #</th>
                            <th width="10%">Expired Date</th>
                            <th width="8%">Check Status</th>
                            <th width="10%">Notes</th>
                            <th width="5%">Qty</th>
                            <th width="20%">Suggested Warehouse Area</th>
                        </tr>
                    </thead>
                    <tbody v-if="form.stock_relocation_receive_line">
                        <tr v-for="(x, i) in form.stock_relocation_receive_line" :key="i">
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input
                                    v-if="i == 0 || form.stock_relocation_receive_line[i].stock_relocation_receive_line_id != form.stock_relocation_receive_line[i - 1].stock_relocation_receive_line_id"
                                    :value="x.sku_code" :readonly="true" disabled class="w-full bg-grey-light" />
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input
                                    v-if="i == 0 || form.stock_relocation_receive_line[i].stock_relocation_receive_line_id != form.stock_relocation_receive_line[i - 1].stock_relocation_receive_line_id"
                                    :value="x.item_name" :readonly="true" disabled class="w-full bg-grey-light" />
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="x.conversion_unit" :readonly="true" disabled
                                    v-if="i == 0 || form.stock_relocation_receive_line[i].conversion_key != form.stock_relocation_receive_line[i - 1].conversion_key"
                                    class="w-full bg-grey-light" />
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="x.conversion_quantity" :readonly="true" disabled
                                    v-if="i == 0 || form.stock_relocation_receive_line[i].conversion_key != form.stock_relocation_receive_line[i - 1].conversion_key"
                                    class="w-full bg-grey-light" />
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="x.check_quantity" :readonly="true" disabled
                                    v-if="i == 0 || form.stock_relocation_receive_line[i].stock_relocation_receive_line_conversion_id != form.stock_relocation_receive_line[i - 1].stock_relocation_receive_line_conversion_id"
                                    class="w-full bg-grey-light" />
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="x.batch_external" :readonly="true"
                                    v-if="i == 0 || form.stock_relocation_receive_line[i].stock_relocation_receive_line_conversion_id != form.stock_relocation_receive_line[i - 1].stock_relocation_receive_line_conversion_id"
                                    disabled class="w-full bg-grey-light" />
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="x.expired_date" :readonly="true"
                                    v-if="i == 0 || form.stock_relocation_receive_line[i].stock_relocation_receive_line_conversion_id != form.stock_relocation_receive_line[i - 1].stock_relocation_receive_line_conversion_id"
                                    disabled class="w-full bg-grey-light" />
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="checkOption[x.status_damage].name" :readonly="true" disabled
                                    v-if="i == 0 || form.stock_relocation_receive_line[i].stock_relocation_receive_line_conversion_id != form.stock_relocation_receive_line[i - 1].stock_relocation_receive_line_conversion_id"
                                    class="w-full bg-grey-light" />
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-textarea v-model="x.notes" class="bg-grey-light"></vs-textarea>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input type="number" v-model="x.quantity" class="w-full bg-grey-light" disabled />
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="x.warehouse_area.name" class="w-full bg-grey-light" disabled />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </vx-card>
</template>

<script>
import Date from "@/components/Date.vue";
import CustomMSelect from "@/components/CustomMultiSelect.vue";
import moment from "moment";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import WarehouseArea from "./components/WarehouseArea.vue";

export default {
    components: {
        Date,
        WarehouseArea,
        CustomMSelect,
        vSelect,
        Datepicker,
    },
    mounted() {
        this.id = this.$route.params.id;
        this.mode = this.$route.params.mode;
        if (this.id) {
            this.initData();
        } else {
            this.$vs.loading();
            setTimeout(this.$vs.loading.close, 3200);
        }
    },
    data() {
        return {
            dialog: {
                save: false,
            },
            popShow: false,
            rerendr: true,
            checkOption: [{
                id: 0,
                name: "Passed"
            }, {
                id: 1,
                name: "Damaged"
            }, {
                id: 2,
                name: "Expired"
            }],
            id: null,
            attachment: null,
            form: {
                gr_document_number: null,
                note: null,
                shipment_number: null,
                delivery_note_number: null,
                stock_relocation_receive_line: null,
            },
        }
    },
    methods: {
        handleBack() {
            this.$vs.loading();
            this.$router.push({
                name: this.$store.state.inbound.interWarehouse.baseRouterName,
            });
            this.$vs.loading.close();
        },
        initData() {
            this.$vs.loading();
            this.$http
                .get(`${this.$store.state.inbound.interWarehouse.baseUrlPath}/receive/put-away/edit-dto/${this.id}`)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.form = resp.data;
                        if (this.form.stock_relocation_receive_line) {
                            this.form.stock_relocation_receive_line.forEach(x => {
                                x.expired_date = x.expired_date.match("0001-01-01") ? null : this.formatDate(x.expired_date);
                            });
                        }
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('YYYY-MM-DD');
            };
        },
        formatLocalNumber: () => {
            return (val) => {
                return Number(val).toLocaleString("id-ID");
            };
        },
    },
}
</script>

<style>
td.td.vs-table--td {
    vertical-align: text-top !important;
}

.vs-input--input:disabled,
input:disabled {
    pointer-events: auto !important;
}

.upload {
    display: flex;
}

.upload button.up {
    margin: 0px !important;
}

.upload button.btnx {
    margin-left: 10px !important;
    border-radius: 5px 0px 0px 5px;
}

.upload button.btn-drop {
    border-radius: 0px 5px 5px 0px;
    border-left: 1px solid rgba(255, 255, 255, .2);
}
</style>